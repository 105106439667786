(() => {
  'use strict';
  /*
   * ------------------------------------------ Base Functions ------------------------------------------
   */
  const baseChart = (canvas, options={}) => {
    let ctx = canvas.getContext('2d'),
        total_labels = options.labels.length;

    if (!window.chartNA)
      window.chartNA = {};

    if (window.chartNA[canvas.getAttribute('id')])
      window.chartNA[canvas.getAttribute('id')].destroy();

    window.chartNA[canvas.getAttribute('id')] = new Chart(ctx, {
      type: options.chart || 'bar',
      data: { 
        labels: options.labels, 
        datasets: options.datasets 
      },
      options: { 
        responsive: true, scales: { y: { suggestedMax: 100 } }, indexAxis: 'x',
        plugins: {
          annotation: {
            annotations: {
              red_zone: { type: 'box', yMin: 0, yMax: 39, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(182, 52, 52, 0.2)', borderColor: 'rgba(182, 52, 52)' },
              orange_zone: { type: 'box', yMin: 39, yMax: 60, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(255, 153, 0, 0.2)', borderColor: 'rgba(255, 153, 0)' },
              yellow_zone: { type: 'box', yMin: 60, yMax: 80, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(255, 243, 71, 0.2)', borderColor: 'rgba(255, 243, 71)' },
              green_zone: { type: 'box', yMin: 80, yMax: 100, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(159, 224, 27, 0.2)', borderColor: 'rgba(159, 224, 27)' }
            }
          }
        }
      }
    });
  };

  const baseChartWithoutAnnotations = (canvas, options={}) => {
    let ctx = canvas.getContext('2d');

    if (!window.chartNA)
      window.chartNA = {};

    if (window.chartNA[canvas.getAttribute('id')])
      window.chartNA[canvas.getAttribute('id')].destroy();

    window.chartNA[canvas.getAttribute('id')] = new Chart(ctx, {
      type: options.chart || 'bar',
      data: { 
        labels: options.labels, 
        datasets: options.datasets 
      },
      options: { 
        responsive: true,
        indexAxis: 'x',
      }
    });
  };

  const formatNumber = (number, options={}) => {
    try {
      let final_number = number || 0;

      if (parseInt(options.round) >= 0) {
        final_number = parseFloat(final_number).toFixed(options.round);
      }

      return `${parseFloat(final_number).toLocaleString()}${options.sufix || ''}`;
    } catch (error) {
      return 0;
    }
  };
  /*
   * ------------------------------------------ Async Functions ------------------------------------------
   */
  const fetchAreasOrNetworks = async (url, data) => {
    return fetch(`${url}?` + new URLSearchParams(data)).then(response => response.json())
  };

  const fetchComplexity = async () => {
    let field = document.getElementById('institution-statistics-typefilter').value,
        data = { 
          country: document.getElementById('institution-statistics-countries').value,
          level: document.getElementById('institution-statistics-level-institutions').value,
        };
    
    if (parseInt(document.getElementById('institution-statistics-networksareas').value) > 0) {
      data[field] = document.getElementById('institution-statistics-networksareas').value;
    }
    
    return fetch(`/statistics/institutions/number_by_complexity?${new URLSearchParams(data)}`)
      .then( response => { return response.json() } );
  };

  const fetchCountriesInformation = async (country=undefined) => {
    let params = '';

    if (country) {
      params = `?${new URLSearchParams({ country: country })}`;
    }

    return fetch(`/statistics/institutions/number_by_country${params}`)
      .then( response => { return response.json() } );
  };

  const fetchModules = async () => {
    let field = document.getElementById('institution-statistics-typefilter').value,
        data = { 
          country: document.getElementById('institution-statistics-countries').value,
          level: document.getElementById('institution-statistics-level-institutions').value,
        };
    
    if (parseInt(document.getElementById('institution-statistics-networksareas').value) > 0) {
      data[field] = document.getElementById('institution-statistics-networksareas').value;
    }
    
    return fetch(`/statistics/institutions/country_modules?${new URLSearchParams(data)}`)
      .then( response => { return response.json() } );
  };

  const fetchNetworkLevel = async () => {
    let field = document.getElementById('institution-statistics-typefilter').value,
        data = { 
          country: document.getElementById('institution-statistics-countries').value,
          level: document.getElementById('institution-statistics-level-institutions').value,
        };
    
    if (parseInt(document.getElementById('institution-statistics-networksareas').value) > 0) {
      data[field] = document.getElementById('institution-statistics-networksareas').value;
    }
    
    return fetch(`/statistics/institutions/number_by_levels?${new URLSearchParams(data)}`)
      .then( response => { return response.json() } );
  }

  const fetchStatisticsFields = async () => {
    let field = document.getElementById('institution-statistics-typefilter').value,
        data = { 
          country: document.getElementById('institution-statistics-countries').value,
          level: document.getElementById('institution-statistics-level-institutions').value,
        };
    
    if (parseInt(document.getElementById('institution-statistics-networksareas').value) > 0) {
      data[field] = document.getElementById('institution-statistics-networksareas').value;
    }
    
    return fetch(`/statistics/institutions/average_beds_offices?${new URLSearchParams(data)}`)
      .then( response => { return response.json() } );
  };

  /*
   * ------------------------------------------ Functions ------------------------------------------
   */
  const setComplexity = async () => {
    let info = await fetchComplexity(),
        chart = document.getElementById('institution-statistics--complexity-institutions'),
        chart_options = { labels: [], datasets: [{ label: chart.getAttribute('data-label'), backgroundColor: "#206CAD", data: [], }] };

    info.forEach(complexity => {
      chart_options.labels.push(complexity[0]);
      chart_options.datasets[0].data.push(parseFloat(complexity[1]));
    });
    baseChartWithoutAnnotations(document.getElementById('institution-statistics--complexity-institutions'), chart_options);
  };

  const setCountriesInformation = async () => {
    let info = await fetchCountriesInformation(), 
        chart_options = { labels: [], datasets: [{ label: '%', backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] },
        html = '', color_rating = '';

    // Replace tbody html
    info.country_institutions.forEach(country => {
      html += `<tr><td>${country[0]}</td><td class="text-right">${formatNumber(country[1])}</td></tr>`;
    });
    document.getElementById('institution-statistics--number-institutions').innerHTML = html;

    // Chart data
    info.avg_country.forEach(avg => {
      color_rating = window.commonVariables.color_rating(avg[1] || 0);

      chart_options.labels.push(avg[0]);
      chart_options.datasets[0].borderColor.push(color_rating);
      chart_options.datasets[0].backgroundColor.push(color_rating + 'B3');
      chart_options.datasets[0].data.push(parseFloat(avg[1]).toFixed(1));
    });
    baseChart(document.getElementById('institution-statistics--avg-institutions'), chart_options);
  }

  const setModules = async () => {
    let info = await fetchModules(),
        name_level = document.getElementById('institution-statistics-level-institutions').selectedOptions[0].innerText,
        html = '';

    info.forEach(module => {
      html += `<tr><td>${name_level}</td><td>${module[0]}</td><td class="text-right">${formatNumber(module[1])}</td></tr>`;
    });
    document.getElementById('table-filter-modules').innerHTML = html;
  };

  const setNameNetworkLevel = () => {
    let name_network = document.getElementById('institution-statistics-typefilter').selectedOptions[0].innerText,
        name_level = document.getElementById('institution-statistics-level-institutions').selectedOptions[0].innerText;

    document.getElementById('network-type-name').innerHTML = `(${name_network})`;
    document.getElementById('network-type-name-level').innerHTML = `(${name_network} - ${name_level})`;
  };

  const setNetworkLevelInformation = async ()  => {
    let data = await fetchNetworkLevel(), sum = 0, html = '',
        chart_options = { labels: [document.getElementById('title-institution-statistics--level').innerHTML], datasets: [] },
        bg_colors = ["#D94848", "#206CAD"];

    setNameNetworkLevel();
    data.total_institutions.forEach((total, index) => {
      sum += parseInt(total[1]);
      html += `<tr><td>${total[0]}</td><td class="text-right">${formatNumber(total[1])}</td></tr>`;

      chart_options.datasets.push({
        label: total[0],
        data: [parseInt(total[1])],
        backgroundColor: bg_colors[index],
      });
    });
    html += `<tr class="bg-primary"><td colspan="2" class="text-right font-weight-bolder h5">${formatNumber(sum)}</td></tr>`;
    document.getElementById('total-instituions-country').innerHTML = html;

    document.getElementById('tbody-total-instituions-network').innerHTML = `<tr class="bg-info"><td class="text-center font-weight-bolder h3">${formatNumber(data.network_institutions)}</td></tr>`;
    document.getElementById('tbody-total-instituions-level').innerHTML = `<tr class="bg-info"><td class="text-center font-weight-bolder h3">${formatNumber(data.level_institutions)}</td></tr>`;

    document.getElementById('repeat-total-instituions-level').innerHTML = `(${formatNumber(data.level_institutions)})`;
    
    baseChartWithoutAnnotations(document.getElementById('institution-statistics--level-institutions'), chart_options);
  };

  const setStatisticsFields = async () => {
    let info = await fetchStatisticsFields();

    // Average Offices
    if (info.avg_offices === 0) {
      document.getElementById('avg-offices').parentElement.classList.add('d-none');
    } else {
      document.getElementById('avg-offices').parentElement.classList.remove('d-none');
    }
    document.getElementById('avg-offices').innerHTML = formatNumber(info.avg_offices, { round: 1 });

    // Average Beds
    if (info.avg_beds === 0) {
      document.getElementById('avg-beds').parentElement.classList.add('d-none');
    } else {
      document.getElementById('avg-beds').parentElement.classList.remove('d-none');
    }
    document.getElementById('avg-beds').innerHTML = formatNumber(info.avg_beds, { round: 1 });

    // Average Critical Beds
    if (info.avg_critical_beds === 0) {
      document.getElementById('avg-critical-beds').parentElement.classList.add('d-none');
    } else {
      document.getElementById('avg-critical-beds').parentElement.classList.remove('d-none');
    }
    document.getElementById('avg-critical-beds').innerHTML = formatNumber(info.avg_critical_beds, { round: 1 });

    // Total Offices
    if (info.total_offices === 0) {
      document.getElementById('total-offices').parentElement.classList.add('d-none');
    } else {
      document.getElementById('total-offices').parentElement.classList.remove('d-none');
    }
    document.getElementById('total-offices').innerHTML = formatNumber(info.total_offices);

    // Total Beds
    if (info.total_beds === 0) {
      document.getElementById('total-beds').parentElement.classList.add('d-none');
    } else {
      document.getElementById('total-beds').parentElement.classList.remove('d-none');
    }
    document.getElementById('total-beds').innerHTML = formatNumber(info.total_beds);

    // Total Hospital Beds
    if (info.total_hospital_beds === 0) {
      document.getElementById('total-hospital-beds').parentElement.classList.add('d-none');
    } else {
      document.getElementById('total-hospital-beds').parentElement.classList.remove('d-none');
    }
    document.getElementById('total-hospital-beds').innerHTML = formatNumber(info.total_hospital_beds);

    // Total Critical Beds
    if (info.total_critical_beds === 0) {
      document.getElementById('total-critical-beds').parentElement.classList.add('d-none');
    } else {
      document.getElementById('total-critical-beds').parentElement.classList.remove('d-none');
    }
    document.getElementById('total-critical-beds').innerHTML = formatNumber(info.total_critical_beds);
  };
  /*
   * ------------------------------------------ Observers ------------------------------------------
   */
  const changeAreaNetwork = () => {
    $("#institution-statistics-networksareas").change(async event => {
      setNetworkLevelInformation();
      setComplexity();
      setStatisticsFields();
      setModules();
    });
  };

  const changeCountry = () => {
    $("#institution-statistics-countries").change(event => {
      $("#institution-statistics-typefilter").trigger('change');
    });
  };

  const changeInstitutionLevel = () => {
    $("#institution-statistics-level-institutions").change(async event => {
      setNetworkLevelInformation();
      setComplexity();
      setStatisticsFields();
      setModules();
    });
  };

  const changeTypeNetworks = () => {
    $("#institution-statistics-typefilter").change(async event => {
      let type_network = event.currentTarget.value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, ''),
          url = type_network === 'geographical_area' ? '/geographical_areas.json' : '/health_networks.json',
          name_field = type_network === 'geographical_area' ? 'name_area' : 'name_network',
          data = { }, info = [], 
          html = '<option value=""></option>';

      data[`${type_network}[administrative_division_id]`] = document.getElementById('institution-statistics-countries').value;
      info = await fetchAreasOrNetworks(url, data);
      
      info.networks.forEach(network => {
        html += `<option value="${network.id}">${network[name_field]}</option>`;
      });
      document.getElementById('institution-statistics-networksareas').innerHTML = html;

      document.getElementById('institution-statistics-typefilter-text').innerHTML = event.currentTarget.selectedOptions[0].innerText;
      $("#institution-statistics-networksareas").trigger('change');
    });
  };

  const downloadFile = () => {
    $("#download-institutions-info").click(event => {
      let field = document.getElementById('institution-statistics-typefilter').value,
          data = { 
            country: document.getElementById('institution-statistics-countries').value,
            level: document.getElementById('institution-statistics-level-institutions').value,
          };
      
      if (parseInt(document.getElementById('institution-statistics-networksareas').value) > 0) {
        data[field] = document.getElementById('institution-statistics-networksareas').value;
      }

      window.location = `/statistics/institutions/download.xlsx?${new URLSearchParams(data).toString()}`;
    });
  };

  /*
   * ------------------------------------------ Main Processes ------------------------------------------
   */
  const initializer = () => {
    setCountriesInformation();
    changeCountry();
    changeTypeNetworks();
    changeAreaNetwork();
    changeInstitutionLevel();
    downloadFile();
    $("#institution-statistics-countries").trigger('change');
  }


  /*
   * --------------------------------------------- Execute ---------------------------------------------
   */
  document.addEventListener("turbolinks:load", () => {
    if ($("#institution-statistics-report").length) {
      initializer();
    }
  });
})();