window.commonVariables  = (() => {
  // Random Color RGB
  var rgbRandom = () => {
    let r = parseInt(0 + Math.random() * (255 - 0)),
        g = parseInt(0 + Math.random() * (255 - 0)),
        b = parseInt(0 + Math.random() * (255 - 0));
    
    return `rgb(${r}, ${g}, ${b})`;
  };
  
  // Color assignment according to rating 
  var colorRating = (rating) => {
    let color = "#bb3434";

    if (rating > 80)
      color = '#9FE01B';
    else if (rating > 60)
      color = '#fff347';
    else if (rating > 39)
      color = '#ff9900';

    return color;
  };

  var suitableTextColor = (bgcolor) => {
    let color = "#000";

    if (bgcolor === '#bb3434') {
      color = "#FFF"
    }

    return color;
  };

  return {
    color_rating: colorRating,
    suitable_text_color: suitableTextColor,
    rgb_random: rgbRandom
  };
})();