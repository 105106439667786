(() => {
  "use strict";
  // Base of charts for countries
  /*
   * --------------------------------------------- Mandatory Varaibles ---------------------------------------------
   * data-results -> Result attribute inside data, data to be displayed in the graph, it must be an array of arrays 
   *                 The first element must be the text to display and the second the quantitative value 
   *                 ([ [ 'Element 1', 30 ] ]).
   * i18n         -> Attribute inside of data, translations for title and the label chart; structure:
   *                 { title: 'Title of Chart', label: 'Name label of data' }
   */
  var base_chart_countries = (dom_chart_element, opts={}) => {
    let id_chart = dom_chart_element.replace('#', ''),
        i18n = $(dom_chart_element).data('i18n'),
        results = $(dom_chart_element).data('results'),
        labels = results.map( (info) => { return info[0]; } ),
        data = results.map( (info) => { return info[1]; } ),
        color_results = data.map( (percentage) => { return window.commonVariables.color_rating(percentage); } );
    
    if (!window.chartCountries)
      window.chartCountries = {};

    window.chartCountries[id_chart] = new Chart($(dom_chart_element), {
      type: opts.type || 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: i18n.label_chart,
            data: data,
            borderWidth: 1,
            borderColor: color_results,
            backgroundColor: color_results.map( (p) => { return p + 'B3'; } )
          }
        ]
      },
      options: { 
        //scales: { xAxes: [{ ticks: { beginAtZero:true, precision: 0, suggestedMax: 100 } }] },
        responsive: true,
        scales: {
          x: { suggestedMax: 100 }
        },
        indexAxis: opts.indexAxis || 'x',
        plugins: {
          title: { 
            display: true, 
            text: i18n.title 
          },
          annotation: {
            annotations: opts.annotations || {}
          }
        }
      }
    });
  };

  var draw_graph_institution = () => {
    // Module Chart
    const total_modules = $("#submodules-institution-tabContent canvas").length;
    const annotations = {
      red_zone: {
        type: 'box',
        xMin: 0, xMax: 39, yMin: -1, yMax: total_modules,
        backgroundColor: 'rgba(182, 52, 52, 0.2)',
        borderColor: 'rgba(182, 52, 52)'
      },
      orange_zone: {
        type: 'box',
        xMin: 39, xMax: 60, yMin: -1, yMax: total_modules,
        backgroundColor: 'rgba(255, 153, 0, 0.2)',
        borderColor: 'rgba(255, 153, 0)'
      },
      yellow_zone: {
        type: 'box',
        xMin: 60, xMax: 80, yMin: -1, yMax: total_modules,
        backgroundColor: 'rgba(255, 243, 71, 0.2)',
        borderColor: 'rgba(255, 243, 71)'
      },
      green_zone: {
        type: 'box',
        xMin: 80, xMax: 100, yMin: -1, yMax: total_modules,
        backgroundColor: 'rgba(159, 224, 27, 0.2)',
        borderColor: 'rgba(159, 224, 27)'
      }
    }
    base_chart_countries("#module-chart-institution", { type: 'bar', indexAxis: 'y', annotations: annotations});
    base_chart_countries("#criteria-chart-institution", { type: 'bar', indexAxis: 'y' });
    $("#submodules-institution-tabContent canvas").each((k, v) => {
      
      let results = $(`#${$(v).attr('id')}`).data('results') || [],
        module_index = results.length > 0 ? $(`tr[data-module="${results[0][3]}"]`).data('index') : 0,
        module_result = results.length > 0 ? $(`tr[data-module="${results[0][3]}"]`).data('percent') : 0,
        data = results.length > 0 ? results.map( (info) => { return info[1]; } ) : [],
        min_data = results.length > 0 ? Math.min.apply(Math, data) : 0;
      
      if (module_result > min_data) {
        let color_module = window.commonVariables.color_rating(module_result),
            color_submodule = window.commonVariables.color_rating(min_data),
            chart = document.getElementById('module-chart-institution').getContext('2d'),
            new_background = chart.createLinearGradient(450, 0, 600, 0);
        
        if (color_module !== color_submodule) {
          new_background.addColorStop(0, color_submodule);
          new_background.addColorStop(1, color_module);

          window.chartCountries['module-chart-institution'].data.datasets[0].borderColor[module_index] = new_background;
          window.chartCountries['module-chart-institution'].data.datasets[0].backgroundColor[module_index] = new_background;
          window.chartCountries['module-chart-institution'].update();
        }
      }
      if (results.length > 0) {
        base_chart_countries(`#${$(v).attr('id')}`, { type: 'bar', indexAxis: 'y' });
      }
    });
  };

  document.addEventListener("turbolinks:load", () => {
    if ($("#module-chart-institution").length)
      draw_graph_institution();
    
    if ($('#submodules-institution-tab').length)
      $('#submodules-institution-tab a').on('click', function (event) {
        event.preventDefault();
        $("#submodules-institution-tabContent div").removeClass('show active');
        $(`#submodules-institution-tabContent ${$(this).attr('href')}`).addClass('show active');
      });

    $("#btn-toggle-module-table").click((e) => {
      let current_text = $('#btn-toggle-module-table span').html(),
          toggle_text = $("#btn-toggle-module-table").data('toggle-text');
      
      $("#btn-toggle-module-table").data('toggle-text', current_text);
      $('#btn-toggle-module-table span').html(toggle_text);
      $("#table-modules").toggleClass("d-none bounceInRight");
    });
  });
})();