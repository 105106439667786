(() => {
  "use strict";
  /*
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */
  const I18n = {
    es: {
      does_not_apply: 'No Aplica',
      not_found_condition: 'Los filtros aplicados no tienen la condición seleccionada, se eliminaron los filtros para que pueda buscar nuevamente la condición deseada.',
    },
    en: {
      does_not_apply: 'Does not apply',
      not_found_condition: 'The applied filters do not have the selected condition, the filters have been removed so that you can search for the desired condition again.',
    },
    pt: {
      does_not_apply: 'Não se aplica',
      not_found_condition: 'Os filtros aplicados não possuem a condição selecionada, os filtros foram removidos para que você possa pesquisar novamente a condição desejada.',
    },
  };

  /*
   * ------------------------------------------------------------------------
   * Getters
   * ------------------------------------------------------------------------
   */
  const getCondition = () => {
    let conditions = JSON.parse(document.getElementById('filters-conditions').getAttribute('data-conditions'));
    
    return conditions[getPosition()];
  };

  const getInstitutionId = () => {
    return document.getElementById('filters-conditions').getAttribute('data-institution-id');
  };

  const getLanguage = () => {
    return document.getElementsByTagName('html')[0].getAttribute('lang');
  };

  const getLastEvaluated = () => {
    return JSON.parse(document.getElementById('filters-conditions').getAttribute('data-last-saved'))[0];
  };

  const getPosition = () => {
    return parseInt(document.getElementById('filters-conditions').getAttribute('data-position'));
  };

  /*
   * ------------------------------------------------------------------------
   * Setters
   * ------------------------------------------------------------------------
   */
  const setNewTotalConditions = total => {
    document.getElementById('total-improvements').innerHTML = `/ ${total}`;
  };

  const setCurrentCondition = () => {
    let position = getPosition();

    document.getElementById('current-conditions').innerHTML = parseInt(position) + 1;
  };

  const setConditionInformation = async () => {
    let condition = '';

    // Change the condition number indicator which is currently displayed
    setCurrentCondition();
    
    condition = await findCondition(getCondition());

    updateConditionInformation(condition.condition);
    updateQuestionInformation(condition.condition.children);
    setImprovementOpportunityPriority(condition.priorization);
    setImprovementOpportunityPlanning(condition.planning);
  };

  const setPosition = (new_position=0) => {
    document.getElementById('filters-conditions').setAttribute('data-position', new_position);
  };

  const setImprovementOpportunityPriority = (priority) => {
    try {
      if (priority) {
        document.querySelector(`[name="priorization_quality_safety"][value="${priority.quality_safety}"]`).checked = true;
        document.querySelector(`[name="priorization_access_services"][value="${priority.access_services}"]`).checked = true;
        document.querySelector(`[name="priorization_intervention_feasibility"][value="${priority.intervention_feasibility}"]`).checked = true;
        document.querySelector(`[name="priorization_improvement"][value="${priority.improvement}"]`).checked = true;
      } else {
        document.querySelector(`[name="priorization_quality_safety"]:checked`).checked = false;
        document.querySelector(`[name="priorization_access_services"]:checked`).checked = false;
        document.querySelector(`[name="priorization_intervention_feasibility"]:checked`).checked = false;
        document.querySelector(`[name="priorization_improvement"]:checked`).checked = false;
      }
    } catch (error) {
      error
    }
    setImprovementOpportunityScore();
  };

  const setImprovementOpportunityPlanning = (planning) => {
    if (planning) {
      document.getElementById(`improvement_opportunity_proposed_date`).value = planning.proposed_date;
      document.getElementById(`improvement_opportunity_execution_manager`).value = planning.execution_manager;
      document.getElementById(`improvement_opportunity_control_manager`).value = planning.control_manager;
      document.getElementById(`improvement_opportunity_importance_execution`).value = planning.importance_execution;
      document.getElementById(`improvement_opportunity_verification_items`).value = planning.verification_items;
      document.getElementById(`improvement_opportunity_expected_results`).value = planning.expected_results;
      document.getElementById(`improvement_opportunity_tasks`).value = planning.tasks;
      document.getElementById(`improvement_opportunity_observations`).value = planning.observations;
    } else {
      document.getElementById(`improvement_opportunity_proposed_date`).value = '';
      document.getElementById(`improvement_opportunity_execution_manager`).value = '';
      document.getElementById(`improvement_opportunity_control_manager`).value = '';
      document.getElementById(`improvement_opportunity_importance_execution`).value = '';
      document.getElementById(`improvement_opportunity_verification_items`).value = '';
      document.getElementById(`improvement_opportunity_expected_results`).value = '';
      document.getElementById(`improvement_opportunity_tasks`).value = '';
      document.getElementById(`improvement_opportunity_observations`).value = '';
    }
  };

  const setImprovementOpportunityScore = () => {
    let score = 0, 
        bg_color = '',
        regx = new RegExp('\\b' + 'label-' + '[^ ]*[ ]?\\b', 'g');;
    
    document.querySelectorAll('.priorization-matrix:checked').forEach(element => {
      score += parseInt(element.value);
    });

    bg_color = colorPriorization(score);
    
    document.getElementById('prioritization-score').className = document.getElementById('prioritization-score').className.replace(regx, '');

    document.getElementById('prioritization-score').classList.add(bg_color);
    document.getElementById('prioritization-score').innerHTML = score;
  };

  /*
   * ------------------------------------------------------------------------
   * HTML elements
   * ------------------------------------------------------------------------
   */
  const updateDataAttributeConditions = info => {
    document.getElementById('filters-conditions').setAttribute('data-conditions', JSON.stringify(info.conditions));
    document.getElementById('filters-conditions').setAttribute('data-last-saved', JSON.stringify(info.last_saved));
    setNewTotalConditions(info.conditions.length);
  };

  const updateConditionInformation = condition => {
    let html = `<tr>
        <td>${condition.module}</td>
        <td>${condition.submodule}</td>
        <td>${condition.code} - ${condition.question}</td>
      </tr>`;
    
    document.getElementById('tbody-condition').innerHTML = html;
  };

  const updateOpportunitiesGenerated = opportunities => {
    let html = '';

    opportunities.forEach(opp => {
      html += `<tr>
          <td>
            <a id="opp-generated__body-id" data-id="${opp.ax_body_id}">
              ${opp.condition}
            </a>
          </td>
          <td>
            <label class="label ${colorPriorization(parseInt(opp.score))}" style="font-size: 20px !important;">${opp.score}</label>
          </td>
        </tr>`;
    });

    document.getElementById('opportunities-generated-body').innerHTML = html;
  };

  const updateQuestionInformation = questions => {
    let html = '';

    questions.forEach(q => {
      html += `<tr>
          <td>${q.text_question}</td>
          <td>${q.does_not_apply ? I18n[getLanguage()]['does_not_apply'] : q.answer_text}</td>
        </tr>`;
    });

    document.getElementById('tbody-questions').innerHTML = html;
  };

  const updateSubmodulesOptions = submodules => {
    let text_all_option = document.getElementById("ax_modules").getAttribute('data-all-option'),
        html = `<option value="">${text_all_option}</option>`;

    try {
      submodules.forEach(submodule => {
        html += `<option value="${submodule.id}">${submodule.description}</option>`;
      });
    } catch (error) {
      error;
    }

    $('select#ax_submodules').html(html).select2();
  };

  /*
   * ------------------------------------------------------------------------
   * Ajax calls
   * ------------------------------------------------------------------------
   */
  // Find Submodules
  const findSubmodules = async (module) => {
    return fetch(`/parameterization/ax_modules/${module}/submodules`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      })
    }).then(response => response.json())
  };

  const findUpdatedListModules = async () => {
    return fetch(`/institutions/${getInstitutionId()}/improvement_opportunities.json?` + new URLSearchParams({
      module_id: document.getElementById('ax_modules').value,
      submodule_id: document.getElementById('ax_submodules').value,
      type_rating: document.getElementById('types_improvement_opportunities').value,
    }), {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      })
    }).then(response => response.json())
  };

  const findCondition = async (condition_id) => {
    return fetch(`/institutions/${getInstitutionId()}/detail_improvement_opportunity`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }),
      body: JSON.stringify({
        condition_id: condition_id,
        type_rating: document.getElementById('types_improvement_opportunities').value,
      })
    }).then(response => response.json())
  };

  const findOpportunitiesGenerated = async () => {
    return fetch(`/institutions/${getInstitutionId()}/improvement_opportunities_generated`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      })
    }).then(response => response.json())
  };

  const saveImprovement = async () => {
    return fetch(`/institutions/${getInstitutionId()}/save_improvement_opportunities`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }),
      body: JSON.stringify({
        improvement_opportunity: {
          ax_body_id: getCondition(),
          quality_safety: $("input[name='priorization_quality_safety']:checked").val(),
          access_services: $("input[name='priorization_access_services']:checked").val(),
          intervention_feasibility: $("input[name='priorization_intervention_feasibility']:checked").val(),
          improvement: $("input[name='priorization_improvement']:checked").val(),
          proposed_date: $("#improvement_opportunity_proposed_date").val(),
          execution_manager: $("#improvement_opportunity_execution_manager").val(),
          control_manager: $("#improvement_opportunity_control_manager").val(),
          importance_execution: $("#improvement_opportunity_importance_execution").val(),
          verification_items: $("#improvement_opportunity_verification_items").val(),
          expected_results: $("#improvement_opportunity_expected_results").val(),
          observations: $("#improvement_opportunity_observations").val(),
          tasks: $("#improvement_opportunity_tasks").val(),
        }
      })
    }).then(response => response.json())
  };

  /*
   * ------------------------------------------------------------------------
   * Initiators and observers
   * ------------------------------------------------------------------------
   */
  const activeTabOppGenerated = () => {
    $("#opportunities-generated-tab").click(async (event) => {
      let opportunities = [];

      opportunities = await findOpportunitiesGenerated();
      updateOpportunitiesGenerated(opportunities);
    });
  };

  const changeFilters = () => {
    $('.select-filter').change(async (event) => {
      let submodules = [], new_info = [], module = document.getElementById('ax_modules').value;
      
      if (event.currentTarget.getAttribute('id') === 'ax_modules') {
        if (module > 0) {
          submodules = await findSubmodules(module);
        }
        updateSubmodulesOptions(submodules);
      }
      
      new_info = await findUpdatedListModules();
      updateDataAttributeConditions(new_info);
      
      // Define position in case some condition has already been evaluated
      initializePosition();
      
      // Initialize current condition information
      await setConditionInformation();
    });
  };

  const changePriority = () => {
    $(".priorization-matrix").change(event => {
      setImprovementOpportunityScore();
    });
  };

  const colorPriorization = score => {
    let bg_color = 'label-info';

    if (score > 16) {
      bg_color = 'label-danger';
    } else if (score > 12) {
      bg_color = 'label-warning';
    } else if (score > 0) {
      bg_color = 'label-success';
    }
    
    return bg_color;
  };

  const conditionNavigation = () => {
    $(".navigation-button").click(async (event) => {
      let mov = parseInt(event.currentTarget.getAttribute('data-mov')),
          total_conditions = JSON.parse(document.getElementById('filters-conditions').getAttribute('data-conditions')).length,
          current_position = getPosition(),
          new_position = current_position + mov;
      
      $(".navigation-button").addClass('disabled');
      
      if (new_position >= 0 && new_position < total_conditions) {
        setPosition(new_position);
        await setConditionInformation();
      }

      $(".navigation-button").removeClass('disabled');
    });
  }

  const initializePosition = () => {
    let conditions = JSON.parse(document.getElementById('filters-conditions').getAttribute('data-conditions')),
        last_evaluated_condition = getLastEvaluated();
    
    if (last_evaluated_condition) {
      document.getElementById('filters-conditions').setAttribute('data-position', conditions.indexOf(last_evaluated_condition));
    } else {
      document.getElementById('filters-conditions').setAttribute('data-position', 0);
    }
  };

  const saveImprovementOpportunityButton = () => {
    $("#save-improvement").click(async (event) => {
      $("#save-improvement").addClass('disabled');
      await saveImprovement();
      $("#save-improvement").removeClass('disabled');
      document.getElementById('next-condition').scrollIntoView({ behavior: 'smooth', block: 'start'});
    });
  };

  const searchOportunity = () => {
    $(document).on('click', '#opp-generated__body-id', async (event) => {
      let ax_body = parseInt(event.currentTarget.getAttribute('data-id')),
          conditions = JSON.parse(document.getElementById('filters-conditions').getAttribute('data-conditions')),
          new_position = conditions.indexOf(ax_body);
          
      if (new_position >= 0) {
        setPosition(new_position);

        await setConditionInformation();

        document.getElementById('planning-tab').scrollIntoView({ behavior: 'smooth', block: 'start'});
        $("#planning-tab").trigger('click');
      } else {
        await $("#ax_modules").val("").trigger('change');
        alert(I18n[getLanguage()].not_found_condition);
      }
    });
  };
  
  /*
   * ------------------------------------------------------------------------
   * Main Initializer
   * ------------------------------------------------------------------------
   */
  document.addEventListener("turbolinks:load", async () => {
    if (document.querySelectorAll('#types_improvement_opportunities').length) {
      // Initialize current condition information
      await setConditionInformation();
      
      /*
       * Watchers and action triggers
       */
      activeTabOppGenerated();
      changeFilters();
      changePriority();
      conditionNavigation();
      saveImprovementOpportunityButton();
      searchOportunity();
    }
  });
})();