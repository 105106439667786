(() => {
  "use strict";

  const baseChart = (canvas, options={}) => {
    let ctx = canvas.getContext('2d'),
        total_labels = options.labels.length;

    if (!window.chartNA)
      window.chartNA = {};

    if (window.chartNA[canvas.getAttribute('id')])
      window.chartNA[canvas.getAttribute('id')].destroy();

    window.chartNA[canvas.getAttribute('id')] = new Chart(ctx, {
      type: options.chart || 'bar',
      data: { 
        labels: options.labels, 
        datasets: options.datasets 
      },
      options: { 
        responsive: true, scales: { y: { suggestedMax: 100 } }, indexAxis: 'x',
        plugins: {
          annotation: {
            annotations: {
              red_zone: { type: 'box', yMin: 0, yMax: 39, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(182, 52, 52, 0.2)', borderColor: 'rgba(182, 52, 52)' },
              orange_zone: { type: 'box', yMin: 39, yMax: 60, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(255, 153, 0, 0.2)', borderColor: 'rgba(255, 153, 0)' },
              yellow_zone: { type: 'box', yMin: 60, yMax: 80, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(255, 243, 71, 0.2)', borderColor: 'rgba(255, 243, 71)' },
              green_zone: { type: 'box', yMin: 80, yMax: 100, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(159, 224, 27, 0.2)', borderColor: 'rgba(159, 224, 27)' }
            }
          }
        }
      }
    });
  };

  const enableDisableSelects = (action='disabled') => {
    document.getElementById('nw-countries').removeAttribute('disabled');
    document.getElementById('nw-type-networks').removeAttribute('disabled');
    document.getElementById('nw-list-type-networks').removeAttribute('disabled');

    if (action === 'disabled') {
      document.getElementById('nw-countries').setAttribute('disabled', true);
      document.getElementById('nw-type-networks').setAttribute('disabled', true);
      document.getElementById('nw-list-type-networks').setAttribute('disabled', true);
    }
  };

  /*
   * ------------------------------------------ Update DOM ------------------------------------------
   */
  const updateStatistics = async (type_network) => {
    let info = await fetch('/network_analysis/network_statistics?' + new URLSearchParams({ 
          country: document.getElementById('nw-countries').value, 
          [type_network]: document.getElementById('nw-list-type-networks').value
        })).then(response => response.json()),
        html = '', color_rating = '',
        chart_options = { labels: [], datasets: [{ label: '%', backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] };
    
    document.getElementById('nw-total-institutions').innerHTML = info.summary.total_institutions;
    document.getElementById('nw-total-institutions-evaluated').innerHTML = info.summary.for_analysis.total_institutions;
    document.getElementById('nw-avg-network').innerHTML = `${info.summary.for_analysis.avg_institutions || 0}%`;

    info.levels_statistics.forEach(data => {
      html += `<tr><td>${data.description}</td><td>${data.total_institutions}</td></tr>`;

      color_rating = window.commonVariables.color_rating(data.avg_institutions);
      chart_options.labels.push(data.description);
      chart_options.datasets[0].borderColor.push(color_rating);
      chart_options.datasets[0].backgroundColor.push(color_rating + 'B3');
      chart_options.datasets[0].data.push(parseFloat(data.avg_institutions));
    });

    document.getElementById('nw-total-institution-levels').innerHTML = html;
    baseChart(document.getElementById('nw-institution-levels'), chart_options);
  };

  const updateChartNetwork = async (type_network) => {
    let info = await fetch('/network_analysis/info_network_analysis?' + new URLSearchParams({ 
          country: document.getElementById('nw-countries').value, 
          [type_network]: document.getElementById('nw-list-type-networks').value,
          level: document.getElementById('nw-levels').value
        })).then(response => response.json()),
        text_na_levels = document.getElementById('nw-levels').options[document.getElementById('nw-levels').selectedIndex].text,
        chart_options = { labels: [], datasets: [{ label: `% ${text_na_levels}`, backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] }, 
        color_rating;
    
    info.modules.forEach(module => {
      color_rating = window.commonVariables.color_rating(module.avg_module);
      chart_options.labels.push(module.title);
      chart_options.datasets[0].borderColor.push(color_rating);
      chart_options.datasets[0].backgroundColor.push(color_rating + 'B3');
      chart_options.datasets[0].data.push(parseFloat(module.avg_module));
    });
    baseChart(document.getElementById('network-modules-institution-level'), chart_options);

    chart_options = { labels: [], datasets: [{ label: `% ${text_na_levels}`, backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] };
    info.criteria.forEach(criterion => {
      color_rating = window.commonVariables.color_rating(criterion[1]);
      chart_options.labels.push(criterion[0]);
      chart_options.datasets[0].borderColor.push(color_rating);
      chart_options.datasets[0].backgroundColor.push(color_rating + 'B3');
      chart_options.datasets[0].data.push(parseFloat(criterion[1]));
    });
    baseChart(document.getElementById('network-criteria-institution-level'), chart_options);
  };

  /*
   * ------------------------------------------ Observers ------------------------------------------
   */
  const countryChange = () => {
    $('#nw-countries').change(async event => {
      $('#nw-type-networks').trigger('change');
    });
  };

  const typeNetworkChange = () => {
    $('#nw-type-networks').change(async event => {
      enableDisableSelects();
      
      let type_network = document.getElementById('nw-type-networks').value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, ''),
          url = type_network === 'geographical_area' ? '/geographical_areas.json' : '/health_networks.json',
          name_field = type_network === 'geographical_area' ? 'name_area' : 'name_network',
          data = { }, info = [], html = '';
      
      data[`${type_network}[administrative_division_id]`] = document.getElementById('nw-countries').value;
      
      info = await fetch(`${url}?` + new URLSearchParams(data)).then(response => response.json());

      info.networks.forEach(network => {
        html += `<option value="${network.id}">${network[name_field]}</option>`;
      });
      
      $("#nw-list-type-networks").html(html).select2();

      await updateStatistics(type_network);
      $("#nw-levels").trigger('change');
      enableDisableSelects('enabled');
    });
  };

  const networkChange = () => {
    $('#nw-list-type-networks').change(async event => {
      enableDisableSelects();

      let type_network = document.getElementById('nw-type-networks').value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, '');

      await updateStatistics(type_network);
      await updateChartNetwork(type_network);

      $("#nw-levels").trigger('change');
      enableDisableSelects('enabled');
    });
  };

  const levelInstitutionChange = () => {
    $("#nw-levels").change(async (event) => {
      let type_network = document.getElementById('nw-type-networks').value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, ''),
          info = await fetch('/network_analysis/list_network_institutions?' + new URLSearchParams({ 
            country: document.getElementById('nw-countries').value, 
            [type_network]: document.getElementById('nw-list-type-networks').value,
            level: document.getElementById('nw-levels').value
          })).then(response => response.json()),
          html = '';
      
      info.forEach(institution => {
        html += `<option value='${institution.id}'>${institution.long_name}</option>`;
      });
      document.getElementById('nw-list-institutions').innerHTML = html;
      $('#nw-list-institutions').select2();
      await updateChartNetwork(type_network);
      $('#nw-list-institutions').trigger('change');
    });
  };

  const institutionChange = () => {
    $("#nw-list-institutions").change(async (event) => {
      let type_network = document.getElementById('nw-type-networks').value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, ''),
          info = await fetch('/network_analysis/info_network_analysis?' + new URLSearchParams({ 
            country: document.getElementById('nw-countries').value, 
            [type_network]: document.getElementById('nw-list-type-networks').value,
            level: document.getElementById('nw-levels').value,
            institution: document.getElementById('nw-list-institutions').value
          })).then(response => response.json()),
          new_dataset = {  },
          chart_module_id = 'network-modules-institution-level',
          chart_criterion_id = 'network-criteria-institution-level',
          info_data;
      
      try {
        new_dataset = { label: document.getElementById('nw-list-type-networks').selectedOptions[0].innerText, data: [], borderColor: '#178FAF', backgroundColor: '#178FAFB3', type: 'line' },
        window.chartNA[chart_module_id].config.data.labels.forEach(module => {
          info_data = info.modules.filter(r => r.title === module);
          new_dataset.data.push(info_data[0] ? info_data[0].avg_module : null);
        });
        window.chartNA[chart_module_id].data.datasets.splice(1, 2);
        window.chartNA[chart_module_id].data.datasets.push(new_dataset);
        window.chartNA[chart_module_id].update();
  
        new_dataset = { label: document.getElementById('nw-list-type-networks').selectedOptions[0].innerText, data: [], borderColor: '#178FAF', backgroundColor: '#178FAFB3', type: 'line' },
        window.chartNA[chart_criterion_id].config.data.labels.forEach(criterion => {
          info_data = info.criteria.filter(r => r[0] === criterion);
          new_dataset.data.push(info_data[0] ? info_data[0][1] : null);
        });
        
        window.chartNA[chart_criterion_id].data.datasets.splice(1, 2);
        window.chartNA[chart_criterion_id].data.datasets.push(new_dataset);
        window.chartNA[chart_criterion_id].update();
      } catch (error) {
        true;
      }
    });
  };

  /*
   * ------------------------------------------ Main Processes ------------------------------------------
   */
  const initializer = () => {
    countryChange();
    typeNetworkChange();
    networkChange();
    levelInstitutionChange();
    institutionChange();
  }

  /*
   * --------------------------------------------- Execute ---------------------------------------------
   */
  document.addEventListener("turbolinks:load", () => {
    if ($("#nw-countries").length) {
      initializer();
      $("#nw-countries").trigger('change');
    }
  });
})();