window.CtrlDatatable = (()=>{
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   **/
  var asyncDatatables = {};
  var ASYNC_DATATABLES = "[data-datatable='async']";
  var SYNC_DATATABLES = "[data-datatable='sync']";
  var LANGUAGE_DATATABLES = {
    'es': {
      "sProcessing":     "Procesando...",
      "sLengthMenu":     "Mostrar _MENU_ registros",
      "sZeroRecords":    "No se encontraron resultados",
      "sEmptyTable":     "Ningún dato disponible en esta tabla =(",
      "sInfo":           "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
      "sInfoEmpty":      "Mostrando registros del 0 al 0 de un total de 0 registros",
      "sInfoFiltered":   "(filtrado de un total de _MAX_ registros)",
      "sInfoPostFix":    "",
      "sSearch":         "Buscar:",
      "sUrl":            "",
      "sInfoThousands":  ",",
      "sLoadingRecords": "Cargando...",
      "oPaginate": {
        "sFirst":    "Primero",
        "sLast":     "Último",
        "sNext":     "Siguiente",
        "sPrevious": "Anterior"
      },
      "oAria": {
        "sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
        "sSortDescending": ": Activar para ordenar la columna de manera descendente"
      },
      "buttons": {
        "copy": "Copiar",
        "colvis": "Visibilidad"
      } 
    },
    'en': {
      "sEmptyTable":     "No data available in table",
      "sInfo":           "Showing _START_ to _END_ of _TOTAL_ entries",
      "sInfoEmpty":      "Showing 0 to 0 of 0 entries",
      "sInfoFiltered":   "(filtered from _MAX_ total entries)",
      "sInfoPostFix":    "",
      "sInfoThousands":  ",",
      "sLengthMenu":     "Show _MENU_ entries",
      "sLoadingRecords": "Loading...",
      "sProcessing":     "Processing...",
      "sSearch":         "Search:",
      "sZeroRecords":    "No matching records found",
      "oPaginate": {
        "sFirst":    "First",
        "sLast":     "Last",
        "sNext":     "Next",
        "sPrevious": "Previous"
      },
      "oAria": {
        "sSortAscending":  ": activate to sort column ascending",
        "sSortDescending": ": activate to sort column descending"
      }
    },
    'pt': {
      "sEmptyTable": "Nenhum registro encontrado",
      "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
      "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
      "sInfoFiltered": "(Filtrados de _MAX_ registros)",
      "sInfoPostFix": "",
      "sInfoThousands": ".",
      "sLengthMenu": "_MENU_ resultados por página",
      "sLoadingRecords": "Carregando...",
      "sProcessing": "Processando...",
      "sZeroRecords": "Nenhum registro encontrado",
      "sSearch": "Pesquisar",
      "oPaginate": {
        "sNext": "Próximo",
        "sPrevious": "Anterior",
        "sFirst": "Primeiro",
        "sLast": "Último"
      },
      "oAria": {
        "sSortAscending": ": Ordenar colunas de forma ascendente",
        "sSortDescending": ": Ordenar colunas de forma descendente"
      },
      "select": {
        "rows": {
          "_": "Selecionado %d linhas",
          "0": "Nenhuma linha selecionada",
          "1": "Selecionado 1 linha"
        }
      }
    },
    'fr': {
      "sEmptyTable":     "Aucune donnée disponible dans le tableau",
      "sInfo":           "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
      "sInfoEmpty":      "Affichage de l'élément 0 à 0 sur 0 élément",
      "sInfoFiltered":   "(filtré à partir de _MAX_ éléments au total)",
      "sInfoPostFix":    "",
      "sInfoThousands":  ",",
      "sLengthMenu":     "Afficher _MENU_ éléments",
      "sLoadingRecords": "Chargement...",
      "sProcessing":     "Traitement...",
      "sSearch":         "Rechercher :",
      "sZeroRecords":    "Aucun élément correspondant trouvé",
      "oPaginate": {
        "sFirst":    "Premier",
        "sLast":     "Dernier",
        "sNext":     "Suivant",
        "sPrevious": "Précédent"
      },
      "oAria": {
        "sSortAscending":  ": activer pour trier la colonne par ordre croissant",
        "sSortDescending": ": activer pour trier la colonne par ordre décroissant"
      },
      "select": {
        "rows": {
          "_": "%d lignes sélectionnées",
          "0": "Aucune ligne sélectionnée",
          "1": "1 ligne sélectionnée"
        } 
      }
    }
  };

  /**
   * ------------------------------------------------------------------------
   * Private Functions
   * ------------------------------------------------------------------------
   **/
  // Get the names of the columns. Only the header columns that have the data col are taken into account
  getColumns = (table, els='thead th[data-col]')=>{
    let columns = [];

    $(els, table).each((k,v)=>{
      columns.push({ 
        data: $(v).data('col'),
        orderable: $(v).data('col') == 'actions' ? false : true,
        sClass: $(v).data('add-class') || ''
      });
    });

    return columns;
  };

  // Translations for datatables
  getTranslation = ()=>{
    let language = $('html').attr('lang');

    return LANGUAGE_DATATABLES[language];
  };

  getColumnsYadcf = (table, els='thead th[data-filter]')=>{
    let columns = [];

    $(els, table).each((k,v)=>{
      if ($(v).data('filter') === 'select2') {
        columns.push({ 
          column_number: k, 
          select_type: "select2", 
          select_type_options: {
            width: '100%',
            placeholder: '',
            allowClear: true  // show 'x' (remove) next to selection inside the select itself
          },
          column_data_type: "html",
          html_data_type: "text",
          filter_reset_button_text: false
        });
      } else if ($(v).data('filter') === 'range_date') {
        columns.push({ 
          column_number: k,
          filter_type: "date",
          style_class: 'form-control',
          reset_button_style_class: 'btn btn-danger',
          datepicker_type: 'bootstrap-datepicker',
          date_format: 'yyyy/mm/dd',
          filter_default_label: '',
          filter_plugin_options: {
            format: 'yyyy/mm/dd',
            showClear: true,
          }
        });
      } else {
        columns.push({ 
          column_number: k, 
          filter_type: $(v).data('filter'), 
          style_class: 'form-control',
          reset_button_style_class: 'btn btn-danger',
          filter_default_label: ''
        });
      }
    });

    return columns;
  };

  getOrder = table => {
    let order = [[0,'asc']];

    $('thead th[data-order]', table).each((k,v)=>{
      order.push([$("thead th", table).index(v), 'asc']);
    });

    return order;
  };

  /**
   * ------------------------------------------------------------------------
   * Public Functions
   * ------------------------------------------------------------------------
   **/
  initAsyncDatatable = ()=>{
    $(ASYNC_DATATABLES).each((k,v)=>{
      $.when(
        $(v).dataTable({
          processing: true,
          serverSide: true,
          language: getTranslation(),
          ajax: {
            url: $(v).data('source'),
            type: 'POST'
          },
          columns: getColumns(v),
          order: getOrder(v)
        }).yadcf(getColumnsYadcf(v))
      ).done(()=>{
        $(".yadcf-filter-wrapper").each((k,v)=>{
          $(v).addClass('input-group');
          $(v).find("button").wrap("<div class='input-group-append'></div>")
        });
      });
    });
  };

  var initSyncDatatables = () => {
    $(SYNC_DATATABLES).each((key, table) => {
      $(table).dataTable({ 
        language: getTranslation(),
        destroy: true,
      });
    });
  };

  document.addEventListener("turbolinks:load", ()=>{
    initAsyncDatatable();
    initSyncDatatables();
  });

  /**
   * ------------------------------------------------------------------------
   * return
   * ------------------------------------------------------------------------
   **/
  return {
    async_tables: initAsyncDatatable
  }
})();