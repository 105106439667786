(() => {
  "use strict";

  const drawNetworks = data => {
    let new_html = "";

    data.networks.forEach(network => new_html += `<option value="${network.id}">${network.name_network}</option>`);

    $("#institution_health_network_ids").html(new_html).val(data.selected).select2();
  };

  const getHealthNetworks = () => {
    $.ajax({
      url: "/health_networks.json",
      method: "GET",
      data: { 
        health_network: { administrative_division_id: $("#institution_administrative_division_id").val() },
        institution: $("#institution_health_network_ids").data('institution')
      },
      beforeSend: e => {
        $("#institution_administrative_division_id").prop('disabled', true); 
        $("#institution_health_network_ids").prop('disabled', true);
      }
    }).done(data => {
      drawNetworks(data);
      $("#institution_administrative_division_id").prop('disabled', false);
      $("#institution_health_network_ids").prop('disabled', false);
    });
  };

  const drawAreas = data => {
    let new_html = "";

    data.networks.forEach(network => new_html += `<option value="${network.id}">${network.name_area}</option>`);

    $("#institution_geographical_area_ids").html(new_html).val(data.selected).select2();
  };

  const getGeographicalArea = () => {
    $.ajax({
      url: "/geographical_areas.json",
      method: "GET",
      data: { 
        geographical_area: { administrative_division_id: $("#institution_administrative_division_id").val() },
        institution: $("#institution_geographical_area_ids").data('institution')
      },
      beforeSend: e => {
        $("#institution_administrative_division_id").prop('disabled', true); 
        $("#institution_geographical_area_ids").prop('disabled', true);
      }
    }).done(data => {
      drawAreas(data);
      $("#institution_administrative_division_id").prop('disabled', false);
      $("#institution_geographical_area_ids").prop('disabled', false);
    });
  };

  const drawOptionsTypeInstitution = (new_options, selected=undefined) => {
    let new_html = "",
        available_selection = new_options.map(el => { return el.id });
    
    new_options.forEach(option => new_html += `<option value=${option.id} data-show-beds=${option.show_beds} data-show-offices=${option.show_offices}>${option.description}</option>`);
    if (selected !== undefined && parseInt(selected) > 0 && available_selection.includes(selected))
      $("#institution_type_institution_id").html(new_html).val(selected).select2().trigger('change');
    else
      $("#institution_type_institution_id").html(new_html).select2().trigger('change');
  };

  const getTypeInstitutions = async institution_level => {
    /*$.ajax({
      url: "/institutions/type_institutions.json",
      method: "GET",
      data: { institution_level: institution_level, institution: $("#institution_type_institution_id").data('institution') },
      beforeSend: e => $("#institution_type_institution_id").prop("disabled", true)
    }).done(data => {
      return data;
    })*/
    $("#institution_type_institution_id").prop("disabled", true);

    return fetch(`/institutions/type_institutions.json?` + new URLSearchParams({
      institution_level: institution_level, 
      institution: $("#institution_type_institution_id").data('institution')
    }))
    .then( response => { return response.json() } )
    .then( json => { return json; } );
  };

  // Geter value selected from institution level
  var getInstitutionLevel = () => {
    return parseInt($("select#institution_institution_level_id").val());
  };

  //
  var getInstitutionId = () => {
    let id = undefined;

    if ($("form[id^=edit_institution]").length > 0) {
      id = $("form[id^=edit_institution]").attr('id').replace('edit_institution_', '');
    }

    return id;
  }

  //
  var checkboxStructure = (record, mandatory=true) => {
    return `<div class="col-4">
      <div class="pretty p-default p-curve w-100">
        <input type="checkbox" name="institution[ax_module_ids][]" value="${record.id}" ${mandatory && record.institution_level_id === getInstitutionLevel() ? 'checked' : ''} data-toggle="tooltip" title="${record.title}" />
        <div class="state p-success-o w-100">
          <label class="w-100 text-truncate" data-toggle="tooltip" title="${record.title}">${record.title}</label>
        </div>
      </div>
    </div>`;
  };

  //
  var createHtmlModules = (data, mandatory) => {
    let html_modules = "",
        modules = data.modules;
    Object.keys(modules).forEach((key) => {
      //html_modules += `<h4>${data.institution_levels[key][0].description}</h4>`;
      html_modules += `<div class="row mb-4">`;
      $(modules[key]).each((k,v) => {
        html_modules += checkboxStructure(v, mandatory);
        if ((k + 1) % 3 === 0) {
          html_modules += `<div class="w-100"></div>`;
        }
      });
      html_modules += `</div>`;
    });

    return html_modules;
  };

  //
  var getModules = (mandatory=true) => {
    let dom_element = mandatory ? "#basic-modules" : "#specialized-modules";
    $.ajax({
      url: $(dom_element).data('url'),
      method: 'GET',
      data: {
        institution_level_id: getInstitutionLevel(),
        type_institution_id: $("#institution_type_institution_id").val(),
        mandatory: mandatory,
        institution_id: getInstitutionId()
      },
      beforeSend: () => { $("select#institution_institution_level_id").prop('disabled', true); }
    }).done((data) => {
      $(dom_element).html(createHtmlModules(data, mandatory));
      
      $(dom_element).find(`input[name="institution[ax_module_ids][]"]`).prop('checked', false);
      
      $(data.institution_modules).each((k,v) => {
        $(dom_element).find(`input[name="institution[ax_module_ids][]"][value=${v}]`).prop('checked', true);
      });

      $('[data-toggle="tooltip"]').tooltip();
      
      $("select#institution_institution_level_id").prop('disabled', false);
    });
  };

  //
  var changeInstitutionLevel = () => {
    let data;
    $("select#institution_institution_level_id").change(async (e) => {
      $(".only-first-level").removeClass("d-none");
      if (parseInt(e.currentTarget.value) === 2) {
        $(".only-first-level").addClass("d-none");
      }

      data = await getTypeInstitutions(e.currentTarget.value);

      drawOptionsTypeInstitution(data.type_institutions, data.type_institution_selected);
      $("#institution_type_institution_id").prop("disabled", false);

      getModules(); // Basic Modules, parameter mandatory true default
      getModules(false); // Specialized Modules, parameter mandatory false
    });
  };

  const updateNetworksChangeCountry = () => {
    getHealthNetworks();
    getGeographicalArea();
    $("#institution_administrative_division_id").change(e => {
      getHealthNetworks(e);
      getGeographicalArea(e);
    });
  };

  var first_load_modules = async () => {
    let data = await getTypeInstitutions($("select#institution_institution_level_id").val());
    
    drawOptionsTypeInstitution(data.type_institutions, data.type_institution_selected);
    $("#institution_type_institution_id").prop("disabled", false);
    
    getModules(); // Basic Modules, parameter mandatory true default
    getModules(false); // Specialized Modules, parameter mandatory false
  };

  var observeTypeInstitution = () => {
    $("#institution_type_institution_id").change(event => {
      $(".content-number-beds").show();
      $("#content-number-offices").show();

      if (!$(event.currentTarget).find('option:selected').data('show-beds')) {
        $(".content-number-beds").hide();
      }

      if (!$(event.currentTarget).find('option:selected').data('show-offices')) {
        $("#content-number-offices").hide();
      }

      getModules(); // Basic Modules, parameter mandatory true default
      getModules(false); // Specialized Modules, parameter mandatory false
    });
  };

  document.addEventListener("turbolinks:load", () => {
    if ($("form#new_institution").length > 0 || $("form[id^=edit_institution]").length > 0) {
      first_load_modules();
      changeInstitutionLevel();
      updateNetworksChangeCountry();
      observeTypeInstitution();
    }
  });
})();