(() => {
  "use strict";
  /*
   * ------------------------------------------ Base Functions ------------------------------------------
   */
  const baseChart = (canvas, options={}) => {
    let ctx = canvas.getContext('2d'),
        total_labels = options.labels.length;

    if (!window.chartNA)
      window.chartNA = {};

    if (window.chartNA[canvas.getAttribute('id')])
      window.chartNA[canvas.getAttribute('id')].destroy();

    window.chartNA[canvas.getAttribute('id')] = new Chart(ctx, {
      type: options.chart || 'bar',
      data: { 
        labels: options.labels, 
        datasets: options.datasets 
      },
      options: { 
        responsive: true, scales: { y: { suggestedMax: 100 } }, indexAxis: 'x',
        plugins: {
          annotation: {
            annotations: {
              red_zone: { type: 'box', yMin: 0, yMax: 39, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(182, 52, 52, 0.2)', borderColor: 'rgba(182, 52, 52)' },
              orange_zone: { type: 'box', yMin: 39, yMax: 60, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(255, 153, 0, 0.2)', borderColor: 'rgba(255, 153, 0)' },
              yellow_zone: { type: 'box', yMin: 60, yMax: 80, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(255, 243, 71, 0.2)', borderColor: 'rgba(255, 243, 71)' },
              green_zone: { type: 'box', yMin: 80, yMax: 100, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(159, 224, 27, 0.2)', borderColor: 'rgba(159, 224, 27)' }
            }
          }
        }
      }
    });
  };

  /*
   * ------------------------------------------ Fetch Request ------------------------------------------
   */
  const fetchAreasOrNetworks = async (url, data) => {
    return fetch(`${url}?` + new URLSearchParams(data)).then(response => response.json())
  };

  const fetchInstitutions = async data => {
    return fetch('/institutions.json?' + new URLSearchParams(data)).then(response => response.json())
  };

  const getCountryStatistics = () => {
    return fetch('/national_analysis/country_statistics?' + new URLSearchParams({ country_id: document.getElementById('na-countries').value })).then(response => response.json())
  }

  const getFilterStatistics = () => {
    let data = { 
          country_id: document.getElementById('na-countries').value,
          institution_level_id: document.getElementById('na-levels').value,
          institution_id: document.getElementById('na-list-institutions').value
        },
        type_network = document.getElementById('na-type-networks').value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, '') + '_id';

    data[type_network] = document.getElementById('na-list-type-networks').value;

    return fetch('/national_analysis/filter_statistics?' + new URLSearchParams(data)).then(response => response.json())
  }

  const getInstitutions = () => {
    let data = { 
      country_id: document.getElementById('na-countries').value,
      institution_level_id: document.getElementById('na-levels').value,
      institution_id: document.getElementById('na-list-institutions').value
    },
    type_network = document.getElementById('na-type-networks').value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, '') + '_id';

    data[type_network] = document.getElementById('na-list-type-networks').value;
    return fetch('/national_analysis/filter_institutions?' + new URLSearchParams(data)).then(response => response.json())
  };

  const getInstLevelStatistics = () => {
    let data = { 
          country_id: document.getElementById('na-countries').value,
          institution_level_id: document.getElementById('na-levels').value,
        };

    return fetch('/national_analysis/institution_level_statistics?' + new URLSearchParams(data)).then(response => response.json())
  }

  /*
   * ------------------------------------------ Update DOM Functions ------------------------------------------
   */
  const setAreasOrNetworks = (information, text_field) => {
    let html = '';

    information.networks.forEach(network => {
      html += `<option value="${network.id}">${network[text_field]}</option>`;
    });
    $("#na-list-type-networks").html(html).select2();
  };

  const setCountryStatistics = info => {
    let html_levels = '', color_rating,
        chart_options = { labels: [], datasets: [{ label: '%', backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] };
    
    document.getElementById('total-institutions').innerHTML = info.summary.total_institutions;
    document.getElementById('total-institutions-evaluated').innerHTML = info.summary.for_analysis.total_institutions;
    document.getElementById('avg-country').innerHTML = `${info.summary.for_analysis.avg_institutions || 0}%`;

    info.levels_statistics.forEach(level => {
      html_levels += `<tr><td>${level.description}</td><td class="text-right">${level.total_institutions}</td></tr>`;

      color_rating = window.commonVariables.color_rating(level.avg_institutions);
      chart_options.labels.push(level.description);
      chart_options.datasets[0].borderColor.push(color_rating);
      chart_options.datasets[0].backgroundColor.push(color_rating + 'B3');
      chart_options.datasets[0].data.push(parseFloat(level.avg_institutions));
    });

    document.getElementById('total-institution-levels').innerHTML = html_levels;
    baseChart(document.getElementById('na-institution-levels'), chart_options);
  };

  const setInstitutions = info => {
    let html_institutions = '';
    
    info.institutions.forEach(institution => {
      html_institutions += `<tr><td>${institution.long_name}</td><td class="text-right">${institution.avg_percentage}%</td></tr>`;
    });
    document.getElementById('institution-list-country').innerHTML = html_institutions;
  };

  const setInstLevelStatistics = info => {
    let text_na_levels = document.getElementById('na-levels').options[document.getElementById('na-levels').selectedIndex].text,
        chart_options = { labels: [], datasets: [{ label: `% ${text_na_levels}`, backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] }, 
        color_rating;
    
    info.module_statistics.forEach(module => {
      color_rating = window.commonVariables.color_rating(module.avg_module);
      chart_options.labels.push(module.title);
      chart_options.datasets[0].borderColor.push(color_rating);
      chart_options.datasets[0].backgroundColor.push(color_rating + 'B3');
      chart_options.datasets[0].data.push(parseFloat(module.avg_module));
    });
    baseChart(document.getElementById('country-modules-institution-level'), chart_options);

    chart_options = { labels: [], datasets: [{ label: '%', backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] };
    info.criterions_statistics.forEach(criterion => {
      color_rating = window.commonVariables.color_rating(criterion[1]);
      chart_options.labels.push(criterion[0]);
      chart_options.datasets[0].borderColor.push(color_rating);
      chart_options.datasets[0].backgroundColor.push(color_rating + 'B3');
      chart_options.datasets[0].data.push(parseFloat(criterion[1]));
    });
    baseChart(document.getElementById('country-criteria-institution-level'), chart_options);
  };
  
  /*
   * ------------------------------------------ Observers ------------------------------------------
   */
  const countryChange = () => {
    $('#na-countries').change(async event => {
      let info = await getCountryStatistics();

      setCountryStatistics(info);
      $('#na-levels').trigger('change');
    });
  };

  const institutionLevelChange = () => {
    $('#na-levels').change(async event => {
      let info = await getInstLevelStatistics();

      setInstLevelStatistics(info);
      $("#na-type-networks").trigger('change');
    });
  };

  const institutionChange = () => {
    $('#na-list-institutions').change(async event => {
      try {
        let info,
            new_dataset = {  },
            chart_module_id = 'country-network-modules-institution-level',
            chart_criterion_id = 'country-criteria-institution-level',
            info_data, 
            data = { 
              country_id: document.getElementById('na-countries').value,
              institution_level_id: document.getElementById('na-levels').value,
              institution_id: document.getElementById('na-list-institutions').value
            },
            type_network = document.getElementById('na-type-networks').value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, '') + '_id',
            position_data,
            chart_options = { labels: [], datasets: [
              { label: `% ${document.getElementById('na-levels').options[document.getElementById('na-levels').selectedIndex].text}`, backgroundColor: [], borderColor: [], borderWidth: 2, data: [], },
              { label: document.getElementById('na-list-type-networks').selectedOptions[0].innerText, data: [], borderColor: '#178FAF', backgroundColor: '#178FAFB3', type: 'line' },
              { label: document.getElementById('na-list-institutions').selectedOptions[0].innerText, data: [], borderColor: '#6E36AF', backgroundColor: '#6E36AFB3', type: 'line' },
            ] };
    
        data[type_network] = document.getElementById('na-list-type-networks').value;
    
        info = await fetch('/national_analysis/filter_institution_statistics?' + new URLSearchParams(data)).then(response => response.json())
        
        info.module_institutions_statistics.forEach(module => {
          chart_options.labels.push(module.title);

          // Get data for Network chart
          position_data = window.chartNA[chart_module_id].config.data.labels.indexOf(module.title);
          
          chart_options.datasets[0].data.push(window.chartNA[chart_module_id].config.data.datasets[0].data[position_data]);
          chart_options.datasets[0].borderColor.push(window.chartNA[chart_module_id].config.data.datasets[0].borderColor[position_data]);
          chart_options.datasets[0].backgroundColor.push(window.chartNA[chart_module_id].config.data.datasets[0].backgroundColor[position_data]);

          chart_options.datasets[1].data.push(window.chartNA[chart_module_id].config.data.datasets[1].data[position_data]);
          
          // Set data netowrk label
          chart_options.datasets[2].data.push(module.avg_module);
        });
        baseChart(document.getElementById('country-institution-modules-institution-level'), chart_options);

        new_dataset = { label: document.getElementById('na-list-institutions').selectedOptions[0].innerText, data: [], borderColor: '#6E36AF', backgroundColor: '#6E36AFB3', type: 'line' },
        window.chartNA[chart_criterion_id].config.data.labels.forEach(criterion => {
          info_data = info.criterions_institutions_statistics.filter(r => r[0] === criterion);
          new_dataset.data.push(info_data[0] ? info_data[0][1] : null);
        });
        window.chartNA[chart_criterion_id].data.datasets.splice(2, 1);
        window.chartNA[chart_criterion_id].data.datasets.push(new_dataset);
        window.chartNA[chart_criterion_id].update();
      } catch (error) {
        baseChart(document.getElementById('country-institution-modules-institution-level'), { labels: [], datasets: [] });
      }
    });
  };

  const networksChange = () => {
    $('#na-list-type-networks').change(async event => {
      try {
        let data = { 
              country_id: document.getElementById('na-countries').value,
              institution_level_id: document.getElementById('na-levels').value
            },
            type_network = document.getElementById('na-type-networks').value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, '') + '_id',
            info,
            chart_module_id = 'country-modules-institution-level',
            text_na_levels = document.getElementById('na-levels').options[document.getElementById('na-levels').selectedIndex].text,
            chart_options = { labels: [], datasets: [
              { label: `% ${text_na_levels}`, backgroundColor: [], borderColor: [], borderWidth: 2, data: [], },
              { label: document.getElementById('na-list-type-networks').selectedOptions[0].innerText, data: [], borderColor: '#178FAF', backgroundColor: '#178FAFB3', type: 'line' },
            ] }, 
            position_data, 
            chart_criterion_id = 'country-criteria-institution-level',
            new_dataset, info_data, html_list_institutions = "";

        data[type_network] = document.getElementById('na-list-type-networks').value;

        info = await fetch('/national_analysis/filter_network_statistics?' + new URLSearchParams(data)).then(response => response.json());

        info.module_statistics.forEach(module => {
          // Get data for country chart
          position_data = window.chartNA[chart_module_id].config.data.labels.indexOf(module.title);
          chart_options.datasets[0].data.push(window.chartNA[chart_module_id].config.data.datasets[0].data[position_data])
          chart_options.datasets[0].borderColor.push(window.chartNA[chart_module_id].config.data.datasets[0].borderColor[position_data])
          chart_options.datasets[0].backgroundColor.push(window.chartNA[chart_module_id].config.data.datasets[0].backgroundColor[position_data])

          // Set data netowrk label
          chart_options.labels.push(module.title);
          chart_options.datasets[1].data.push(module.avg_module);
        });
        baseChart(document.getElementById('country-network-modules-institution-level'), chart_options);
        
        new_dataset = { label: document.getElementById('na-list-type-networks').selectedOptions[0].innerText, data: [], borderColor: '#178FAF', backgroundColor: '#178FAFB3', type: 'line' },
        window.chartNA[chart_criterion_id].config.data.labels.forEach(criterion => {
          info_data = info.criterions_statistics.filter(r => r[0] === criterion);
          new_dataset.data.push(info_data[0] ? info_data[0][1] : null);
        });
        window.chartNA[chart_criterion_id].data.datasets.splice(1, 2);
        window.chartNA[chart_criterion_id].data.datasets.push(new_dataset);
        window.chartNA[chart_criterion_id].update();

        // Get institutions and set data in select input
        data = { only_evaluated: true };
        type_network = document.getElementById('na-type-networks').value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, '');

        data['institution[administrative_division_id]'] = document.getElementById('na-countries').value;
        data['institution[institution_level_id]'] = document.getElementById('na-levels').value;
        data[type_network] = event.currentTarget.value;

        info = await getInstitutions();
        setInstitutions(info);

        info = await fetchInstitutions(data)
        info.institutions.forEach(inst => {
          html_list_institutions += `<option value="${inst.id}">${inst.long_name}</option>`;
        });
        $('#na-list-institutions').html(html_list_institutions).select2();
        $('#na-list-institutions').trigger('change');        
      } catch (error) {
        error;
      }
    })
  };

  const typeNetworksChange = () => {
    $("#na-type-networks").change(async event => {
      let type_network = event.currentTarget.value.replace( /([A-Z])/g, "_$1" ).toLowerCase().replace(/^\_+|\_+$/g, ''),
          url = type_network === 'geographical_area' ? '/geographical_areas.json' : '/health_networks.json',
          name_field = type_network === 'geographical_area' ? 'name_area' : 'name_network',
          data = { }, info = [];

      data[`${type_network}[administrative_division_id]`] = document.getElementById('na-countries').value;
      info = await fetchAreasOrNetworks(url, data);
      
      setAreasOrNetworks(info, name_field);
      document.getElementById('label-list-type-networks').innerHTML = event.currentTarget.selectedOptions[0].innerText;
      $('#na-list-type-networks').trigger('change');
    });
  };

  /*
   * ------------------------------------------ Main Processes ------------------------------------------
   */
  const initializer = () => {
    countryChange();
    institutionLevelChange();
    typeNetworksChange();
    networksChange();
    institutionChange();
  }


  /*
   * --------------------------------------------- Execute ---------------------------------------------
   */
  document.addEventListener("turbolinks:load", () => {
    if ($("#na-countries").length) {
      initializer();
      $("#na-countries").trigger('change');
    }
  });
})();