(()=>{
  /*
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */
  var DOM_EVALUATION_DATA = "#questions-module";
  var DOM_ANCESTRY = {
    description: "#description-ancestry",
    module: "#level-module",
    submodule: "#level-submodule",
    consecutive: "#level-consecutive"
  };
  var DOM_QUESTION = {
    criterion: "#questions-module-criterion",
    question: "#questions-module-question",
    answers: "#questions-module-answers"
  };
  var TRANSLATIONS = {
    answers: {
      not_apply: {
        es: 'No aplica',
        en: 'Does not apply',
        pt: 'Não se aplica'
      }
    }
  };

  /*
   * ------------------------------------------------------------------------
   * Getters
   * ------------------------------------------------------------------------
   */
  // Get current position of the question being evaluated
  getCurrentPosition = () => {
    return $(DOM_EVALUATION_DATA).data('position');
  };

  // Get question identifier
  getQuestionId = () => {
    return $(DOM_EVALUATION_DATA).data("questions")[getCurrentPosition()];
  };

  // Get total questions
  getTotalQuestions = () => {
    try {
      return $(DOM_EVALUATION_DATA).data("questions").length  
    } catch (error) {
      return 0; 
    }
  };

  /*
   * ------------------------------------------------------------------------
   * Setters
   * ------------------------------------------------------------------------
   */
  // Set #current-question, updated value
  setCurrentQuestion = () => {
    $("#current-question").html(getCurrentPosition() + 1);
  };

  // Set next position of the data attribute
  setNewPosition = (new_position=-1) => {
    var current_position = getCurrentPosition();
    if (new_position >= 0) {
      $(DOM_EVALUATION_DATA).data('position', new_position);
    } else {
      $(DOM_EVALUATION_DATA).data('position', current_position + 1);
    }
    setCurrentQuestion();
  };

  // Set ancestry information
  setAncestry = (ancestor) => {
    var code = ancestor.code.split(".");
    
    $(DOM_ANCESTRY.description).html(ancestor.question);
    $(DOM_ANCESTRY.module).html(code[0]);
    $(DOM_ANCESTRY.submodule).html(code[1]);
    $(DOM_ANCESTRY.consecutive).html(code[2]);
  };

  // Set question header
  setQuestionHeader = (question) => {
    $(DOM_QUESTION.question).html(question);
  };
  
  // Set answers question
  setAnswersQuestion = (answers) => {
    $(DOM_QUESTION.answers).html( domAnswerRadio(answers) );
  };

  // Set Criterion
  setCriterion = (criterion) => {
    $(DOM_QUESTION.criterion).html( criterion.description );
  };

  // Set question evaluated
  setQuestionEvaluated = (evaluation) => {
    $(`input[name="qualified_value"][value="${evaluation.qualified_value}"]`).prop('checked', true);
    $('input[name="does_not_apply"]').prop('checked', evaluation.does_not_apply).trigger('change');
    $('#evaluation_body_observations').html(evaluation.observations);
    $(DOM_EVALUATION_DATA + ' tbody').data('evaluation-body', evaluation.id);
  }

  /*
   * ------------------------------------------------------------------------
   * Functions
   * ------------------------------------------------------------------------
   */
  // Valid position not greather than length total questions
  isValidPosition = () => {
    if (getCurrentPosition() > getTotalQuestions() - 1) {
      setNewPosition(getTotalQuestions() - 1);
    } else if (getCurrentPosition() < 0) {
      setNewPosition(0);
    }
  };

  // Disabled buttons [ prev, next, save ]
  disabledButtons = () => {
    $("#prev-question").addClass("disabled");
    $("#next-question").addClass("disabled");
    $("#save-question").addClass("disabled");
  };
  
  // Enabled buttons [ prev, next, save ]
  enabledButtons = () => {
    $("#prev-question").removeClass("disabled");
    $("#next-question").removeClass("disabled");
    $("#save-question").removeClass("disabled");
  };

  /*
   * ------------------------------------------------------------------------
   * HTML elements
   * ------------------------------------------------------------------------
   */
  // HTML structure of radio answers
  domAnswerRadio = (answers) => {
    var dom_html = `<div class="d-block my-2">
      <div class="pretty p-default p-curve">
        <input type="checkbox" name="does_not_apply" />
        <div class="state p-warning-o">
          <label>${TRANSLATIONS['answers']['not_apply'][$("html").attr('lang')]}</label>
        </div>
      </div>
    </div>`;
    
    try {
      answers.forEach(element => {
        dom_html += `<div class="d-block mb-1">
          <div class="pretty p-default p-curve">
            <input type="radio" name="qualified_value" value="${element.quantifiable_value}" />
            <div class="state p-primary-o">
              <label>${element.answer_text}</label>
            </div>
          </div>
        </div>`;
      });
      dom_html += '<div class="d-block mb-2"></div>'

      return dom_html;
    } catch (error) {
      console.error(error);
      console.error("Error each answers");
      return "";
    }
  };

  /*
   * ------------------------------------------------------------------------
   * Ajax calls
   * ------------------------------------------------------------------------
   */
  // Search information for evaluation
  ajaxSearchEvaluation = () => {
    $.ajax({
      url: $(DOM_EVALUATION_DATA + ' tbody').data("url-evaluation").replace('__ID__', getQuestionId()),
      method: 'GET'
    }).done((data) => {
      if (data.evaluation_body) {
        setQuestionEvaluated(data.evaluation_body);
      } else {
        $(DOM_EVALUATION_DATA + ' tbody').data("evaluation-body", "");
        $('#evaluation_body_observations').html('');
      }
      enabledButtons();
    });
  }

  // Search information for the question
  ajaxSearchQuestion = () => {
    isValidPosition();
    $.ajax({
      url: $(DOM_EVALUATION_DATA).data("urlsearch").replace('__ID__', getQuestionId()),
      method: 'GET',
      beforeSend: disabledButtons()
    }).done((data) => {
      setAncestry(data.ancestor);
      setCriterion(data.criterion);
      setQuestionHeader(data.question);
      setAnswersQuestion(data.answer.answer_options);
      ajaxSearchEvaluation();
    });
  };

  // Save answer for the question
  ajaxSaveQuestion = (information) => {
    $.ajax({
      url: $(DOM_EVALUATION_DATA + ' tbody').data("save-eval"),
      method: 'PUT',
      data: information,
      beforeSend: disabledButtons()
    }).done((data) => {
      setNewPosition();
      ajaxSearchQuestion();
      enabledButtons();
    });
  };

  /*
   * ------------------------------------------------------------------------
   * Initiators and observers
   * ------------------------------------------------------------------------
   */
  // Observe does not apply active
  questionDoesNotApply = () => {
    $(document).on('change', 'input[name="does_not_apply"]', (e) => {
      if ($(e.currentTarget).prop('checked')) {
        $('input[name="qualified_value"]:checked').prop('checked', false);
        $('input[name="qualified_value"]').prop('disabled', true);
      } else {
        $('input[name="qualified_value"]').prop('disabled', false);
      }
    });
  };

  // Observe button "save question" clicked
  saveQuestion = () => {
    $("#save-question").click((e) => {
      var information = {
        evaluation_header: {
          evaluation_bodies_attributes: [{
            id: $(DOM_EVALUATION_DATA + ' tbody').data('evaluation-body'),
            ax_body_id: getQuestionId(),
            qualified_value: $('input[name="qualified_value"]:checked').val() || "",
            does_not_apply: $('input[name="does_not_apply"]').prop('checked'),
            observations: $('#evaluation_body_observations').val()
          }]
        }
      };

      ajaxSaveQuestion(information);
    });
  };

  // Observe button next clicked
  nextQuestion = () => {
    $("#next-question").click((e) => {
      var information = {
        evaluation_header: {
          evaluation_bodies_attributes: [{
            id: $(DOM_EVALUATION_DATA + ' tbody').data('evaluation-body'),
            ax_body_id: getQuestionId(),
            qualified_value: $('input[name="qualified_value"]:checked').val() || "",
            does_not_apply: $('input[name="does_not_apply"]').prop('checked'),
            observations: $('#evaluation_body_observations').val()
          }]
        }
      };

      ajaxSaveQuestion(information);
    });
  };
  
  // Observe button prev clicked
  prevQuestion = () => {
    $("#prev-question").click((e) => {
      if (getCurrentPosition() > 0) {        
        var information = {
          evaluation_header: {
            evaluation_bodies_attributes: [{
              id: $(DOM_EVALUATION_DATA + ' tbody').data('evaluation-body'),
              ax_body_id: getQuestionId(),
              qualified_value: $('input[name="qualified_value"]:checked').val() || "",
              does_not_apply: $('input[name="does_not_apply"]').prop('checked'),
              observations: $('#evaluation_body_observations').val()
            }]
          }
        };

        setNewPosition(getCurrentPosition() - 2);
  
        ajaxSaveQuestion(information);
      }
    });
  };

  //
  const goToQuestion = () => {
    $("[data-go-to-question]").click(e => {
      let questions = $(DOM_EVALUATION_DATA).data("questions");
      
      setNewPosition(questions.indexOf(parseInt(e.currentTarget.getAttribute('data-go-to-question'))));
      ajaxSearchQuestion();
    })
  };

  document.addEventListener("turbolinks:load", () => {
    if ($(DOM_EVALUATION_DATA).length > 0) {
      ajaxSearchQuestion();
      questionDoesNotApply();
      saveQuestion();
      nextQuestion();
      prevQuestion();
      goToQuestion();
    }
  });
})();