//window.ctrlSweetalert = 
(()=> {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   **/
  var DOM_DELETE = "[data-sweet-delete]";
  var NOTICE_ALERT = "#app-messages-notice";
  var WARNING_ALERT = "#app-messages-alert";
  var TEXT_DELETE = {
    'es': "Esta acción eliminara el registro seleccionado, solo continue en caso de estar completamente seguro de culminar con el proceso.",
    'en': "This action will delete the selected record, just continue in case you are completely sure to finish the process.",
    'pt': "Esta ação excluirá o registro selecionado, apenas continue caso tenha certeza de concluir o processo.",
    'fr': "Cette action supprimera l'enregistrement sélectionné, continuez simplement au cas où vous êtes sûr de terminer le processus"
  };
  var MSG_DELETE = {
    'deleted': {
      'es': "El registro se borró exitosamente.",
      'en': "The record was successfully deleted.",
      'pt': "O registro foi excluído com sucesso.",
      'fr': "L'enregistrement a été supprimé avec succès."
    },
    'canceled': {
      'es': "Se cancelo el proceso, el registro se encuentra a salvo.",
      'en': "The process was canceled, the registration is safe.",
      'pt': "O processo foi cancelado, o registro é seguro.",
      'fr': "Le processus a été annulé, l'enregistrement est sécurisé."
    }
  };

  /**
   * ------------------------------------------------------------------------
   * Private Methods
   * ------------------------------------------------------------------------
   **/
  // Check if the notice or alert contains information to display
  validNotification = (el)=>{
    try {
      return $(el).html().trim().length > 0  
    } catch (error) {
      return false;
    }
  };

  // Configuration for notice alert
  configNotice = ()=>{
    return {
      title:  $(NOTICE_ALERT).data('title'),
      text:   $(NOTICE_ALERT).html().trim(), 
      icon:   "success"
    };
  };

  // Configuration for warning alert
  configWarning = ()=>{
    return {
      title:  $(WARNING_ALERT).data('title'),
      text:   $(WARNING_ALERT).html().trim(), 
      icon:   "error"
    };
  };

  // Configuration for delete alert
  configDelete = ()=>{
    return {      
      title: $(DOM_DELETE).data('title') || false,
      text: TEXT_DELETE[$('html').attr('lang')],
      icon: 'warning',
      buttons: true,
      dangerMode: true      
    };
  };

  // Show notice only html element content length is greather than 0
  displayNotice = ()=>{
    if (validNotification(NOTICE_ALERT)) {
      swal(configNotice()).then((value) => {
        $(NOTICE_ALERT).html('');
      });
    }
  };

  // Show alert only html element content length is greather than 0
  displayWarning = ()=>{
    if (validNotification(WARNING_ALERT)) {
      swal(configWarning()).then((value) => {
        $(WARNING_ALERT).html('');
      });
    }
  };

  // Alert for delete element (Delete method in most cases it changes the current_state from active to inactive)
  displayDelete = ()=>{
    $(document).on('click', DOM_DELETE, (e)=>{
      e.preventDefault();
      console.log('por lo menos si lo llama');
      
      let current_element = $(e.currentTarget);
      
      swal(configDelete()).then((will_delete)=>{
        if (will_delete) {
          $.ajax({
            url: current_element.data('sweet-delete'),
            dataType: 'JSON',
            method: 'DELETE',
            success: (data)=>{
              swal(MSG_DELETE['deleted'][$('html').attr('lang')], { 
                icon: "success" 
              }).then((confirm)=>{
                Turbolinks.visit(window.location.toString(), {action: 'replace'});
              });
            },
            error: (xhr, text, error)=>{
              if (xhr.status == 403) {
                swal('Error', 'You do not have the necessary permissions', 'warning');
              } else {
                swal('Error', 'Unexpected error please try again, if the error persists contact our technical team', 'error');
              }
            }
          });
        } else {
          swal(MSG_DELETE['canceled'][$('html').attr('lang')]);
        }
      });
    });
  };

  /**
   * ------------------------------------------------------------------------
   * Auto init
   * ------------------------------------------------------------------------
   **/
  document.addEventListener('turbolinks:load', ()=>{
    displayNotice();
    displayWarning();
    displayDelete();
  });
})();