window.simulationResults = (() => {
  "use strict";
  // Random percentage
  var randomPercentage = () => {
    return Math.floor(Math.random() * (100 - 10)) + 10;
  };

  // Color assignment according to rating 
  var colorRating = (rating) => {
    let color = "#bb3434";

    if (rating > 80)
      color = '#9FE01B';
    else if (rating > 60)
      color = '#E6E600';
    else if (rating > 39)
      color = '#FFC000';

    return color;
  };

  // Get Checked Modules
  var getCheckedModules = (elements="[data-module-selection]:checked") => {
    let modules = [];

    $(elements).each((k,v) => {
      modules.push({
        id: $(v).val(),
        text: $(v).data('human')
      });
    });

    return modules;
  };

  // Simulation Analysis - Modules
  var simulationAnalysis = () => {
    let modules = getCheckedModules(),
        labels = modules.map((module) => { return module.text; }),
        data = modules.map((module) => { return randomPercentage(); }),
        colors = data.map((percentage) => { return colorRating(percentage); });
    
    if (window.simulation_charts !== undefined && window.simulation_charts['module_analysis_chart'] !== undefined)
      window.simulation_charts['module_analysis_chart'].destroy();
    let module_chart = new Chart(document.getElementById("module-analysis").getContext('2d'), {
      type: 'horizontalBar',
      data: {
        labels: labels,
        datasets: [{
          label: '%',
          backgroundColor: colors,
          borderColor: [],
          data: data
        }]
      },
      options: { scales: { xAxes: [{ ticks: { beginAtZero:true, precision: 0, suggestedMax: 100 } }] } }
    });

    if (window.simulation_charts === undefined)
      window.simulation_charts = {};
    window.simulation_charts['module_analysis_chart'] = module_chart;
  };

  // Simulation Analysis - Criterions
  var simulationCriteria = () => {
    let labels = $("#criteria-analysis").data('criteria'),
        data = labels.map((label) => { return randomPercentage(); }),
        colors = data.map((percentage) => { return colorRating(percentage); });
    
    if (window.simulation_charts !== undefined && window.simulation_charts['criteria_analysis_chart'] !== undefined)
      window.simulation_charts['criteria_analysis_chart'].destroy();
    let module_chart = new Chart(document.getElementById("criteria-analysis").getContext('2d'), {
      type: 'horizontalBar',
      data: {
        labels: labels,
        datasets: [{
          label: '%',
          backgroundColor: colors,
          borderColor: [],
          data: data
        }]
      },
      options: { scales: { xAxes: [{ ticks: { beginAtZero:true, precision: 0, suggestedMax: 100 } }] } }
    });

    if (window.simulation_charts === undefined)
      window.simulation_charts = {};
    window.simulation_charts['criteria_analysis_chart'] = module_chart;
  };

  document.addEventListener("turbolinks:load", () => {
    $("#button-simulation-analysis").click((e) => {
      simulationAnalysis();
      simulationCriteria();
    });
  });
})();