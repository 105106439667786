(() => {
  "use strict";

  var createBaseInstitution = () => {
    $.ajax({
      url: $('#btn-update-role').data('url-base-institution'),
      method: 'POST',
      data: {
        institution_name: $('#institution_name').html(),
        country: $('#country').data('country'),
        user: $('#user').data('user')
      }
    }).done((data) => {
      swal(data.message).then(() => {
        window.location.href = $('#btn-update-role').data('url-institutions');
      });
    }).fail((xhr, error) => {
      console.log(xhr);
    });
  };

  var updateRole = () => {
    $.ajax({
      url: $('#btn-update-role').data('url'),
      method: 'PUT',
      data: {
        country: $('#country').data('country'),
        institution_name: $('#institution_name').html(),
        new_role: $('select#role').val()
      },
      beforeSend: () => {
        $('#btn-update-role').prop('disabled', true);
        $('select#role').prop('disabled', true);
      }
    }).done((data) => {
      swal({
        title: "",
        text: data.errors,
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((create) => {
        if (create) {
          createBaseInstitution()
        } else {
          window.location.href = $('#btn-update-role').data('url-institutions');
        }
      });
    });
  };

  document.addEventListener("turbolinks:load", () => {
    $('#btn-update-role').click((e) => {
      updateRole();
    });
  });
})();