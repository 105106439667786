(() => {
  "use strict";
  const baseChartAnnotations = (canvas, options={}) => {
    let ctx = canvas.getContext('2d'),
        total_labels = options.labels.length;

    if (!window.chartNA)
      window.chartNA = {};

    if (window.chartNA[canvas.getAttribute('id')])
      window.chartNA[canvas.getAttribute('id')].destroy();

    window.chartNA[canvas.getAttribute('id')] = new Chart(ctx, {
      type: options.chart || 'bar',
      data: { 
        labels: options.labels, 
        datasets: options.datasets 
      },
      options: { 
        indexAxis: options.indexAxis || 'x',
        responsive: true, 
        scales: { y: { suggestedMax: 100 } },
        plugins: {
          annotation: {
            annotations: {
              red_zone: { type: 'box', yMin: 0, yMax: 39, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(182, 52, 52, 0.2)', borderColor: 'rgba(182, 52, 52)' },
              orange_zone: { type: 'box', yMin: 39, yMax: 60, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(255, 153, 0, 0.2)', borderColor: 'rgba(255, 153, 0)' },
              yellow_zone: { type: 'box', yMin: 60, yMax: 80, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(255, 243, 71, 0.2)', borderColor: 'rgba(255, 243, 71)' },
              green_zone: { type: 'box', yMin: 80, yMax: 100, xMin: -1, xMax: total_labels, backgroundColor: 'rgba(159, 224, 27, 0.2)', borderColor: 'rgba(159, 224, 27)' }
            }
          }
        }
      }
    });
  };

  const baseChartNoAnnotations = (canvas, options={}) => {
    let ctx = canvas.getContext('2d'),
        total_labels = options.labels.length;

    if (!window.chartNA)
      window.chartNA = {};

    if (window.chartNA[canvas.getAttribute('id')])
      window.chartNA[canvas.getAttribute('id')].destroy();

    window.chartNA[canvas.getAttribute('id')] = new Chart(ctx, {
      type: options.chart || 'bar',
      data: { 
        labels: options.labels, 
        datasets: options.datasets 
      },
      options: { 
        indexAxis: options.indexAxis || 'x',
        responsive: true, 
        scales: { y: { suggestedMax: 100 } },
        plugins: {
        }
      }
    });
  };

  /**
   * ------------------------------------------ Fetch Requests ------------------------------------------
   */
  const fetchModules = () => {
    return fetch(`/parameterization/ax_modules.json?` + new URLSearchParams({
      'ax_module[institution_level_id]': $("#module-analysis--levels").val()
    })).then(response => response.json())
  };

  const fetchCountriesInformation = () => {
    return fetch(`/module_analysis/countries_information?` + new URLSearchParams({
      'module_id': $("#module-analysis--modules").val()
    })).then(response => response.json())
  };

  const fetchCountrysubmodules = () => {
    return fetch(`/module_analysis/countries_information/country_submodule?` + new URLSearchParams({
      'module_id': $("#module-analysis--modules").val(),
      'country_id': $("#module-analysis--countries").val()
    })).then(response => response.json())
  };

  /**
   * ------------------------------------------ Update DOM ------------------------------------------
   */
  const updateListModules = async () => {
    let modules = await fetchModules(),
        html = '';

    modules.forEach(module => {
      html += `<option value="${module.id}">${module.translation.title}</option>`;
    });

    document.getElementById('module-analysis--modules').innerHTML = html;
    $('module-analysis--modules').select2();
  };

  const updateInformationCountries = async () => {
    let data = await fetchCountriesInformation(),
        chart_options = { indexAxis: 'y', labels: [], datasets: [{ label: '# Instituciones', backgroundColor: "#206CAD", borderWidth: 2, data: [], }] },
        chart_annotations_options = { labels: [], datasets: [{ label: '%', backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] },
        html_table = '', html_avg_countries = '', color_rating;

    data.uses.forEach(r => {
      chart_options.labels.push(r.name_of);
      chart_options.datasets[0].data.push(parseInt(r.total_institutions));
      html_table += `<tr><td>${r.name_of}</td><td class="text-right">${r.total_institutions}</td></tr>`;
    });
    baseChartNoAnnotations(document.getElementById('module-analysis--uses-countries'), chart_options);
    document.getElementById('module-analysis--table-uses-countries').innerHTML = html_table;
    
    chart_options = { indexAxis: 'y', labels: [], datasets: [{ label: '# Instituciones', backgroundColor: "#206CAD", borderWidth: 2, data: [], }] }
    html_table = '';
    data.full_evaluation.info.forEach(r => {
      chart_options.labels.push(r.country);
      chart_options.datasets[0].data.push(parseInt(r.total_institutions));
      html_table += `<tr><td>${r.country}</td><td class="text-right">${r.total_institutions}</td></tr>`;

      color_rating = window.commonVariables.color_rating(r.avg_country);
      chart_annotations_options.labels.push(r.country);
      chart_annotations_options.datasets[0].borderColor.push(color_rating);
      chart_annotations_options.datasets[0].backgroundColor.push(color_rating + 'B3');
      chart_annotations_options.datasets[0].data.push(parseFloat(r.avg_country));
      try {
        html_avg_countries += `<tr><td>${r.country}</td><td class="text-right">${r.avg_country.toFixed(2)}%</td></tr>`;  
      } catch (error) {
        html_avg_countries += `<tr><td>${r.country}</td><td class="text-right">0%</td></tr>`;
      }
    });
    baseChartNoAnnotations(document.getElementById('module-analysis--full-evaluation'), chart_options);
    document.getElementById('module-analysis--table-full-evaluation').innerHTML = html_table;
    baseChartAnnotations(document.getElementById('module-analysis--avg-countries'), chart_annotations_options);
    document.getElementById('module-analysis--table-avg-countries').innerHTML = html_avg_countries;

    try {
      document.getElementById('module-analysis--avg-global').innerHTML = `${data.full_evaluation.avg_percentage.toFixed(2)}%`;
    } catch (error) {
      document.getElementById('module-analysis--avg-global').innerHTML = '0%';
    }
    
  };

  const updateInformationSubmodules = async () => {
    let info = await fetchCountrysubmodules(),
        chart_annotations_options = { labels: [], datasets: [{ label: '%', backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] },
        avg, color_rating;

    info.forEach(r => {
      chart_annotations_options.labels.push(r.submodule);
      try {
        avg = (parseInt(r.total_qualified) / ((parseInt(r.total_questions) - parseInt(r.total_does_not_apply)) * 5)  * 100).toFixed(2)
      } catch (error) {
        avg = 0
      }
      color_rating = window.commonVariables.color_rating(avg);
      chart_annotations_options.datasets[0].data.push(avg);
      chart_annotations_options.datasets[0].borderColor.push(color_rating);
      chart_annotations_options.datasets[0].backgroundColor.push(color_rating + 'B3');
    });
    baseChartAnnotations(document.getElementById('module-analysis--avg-submodules'), chart_annotations_options);
  };
  
  /**
   * ------------------------------------------ Observers ------------------------------------------
   */
  const changeIntitutionLevel = () => {
    $("#module-analysis--levels").change((e) => {
      $("#module-analysis--levels").addClass('disabled');
      updateListModules();
      $("#module-analysis--levels").removeClass('disabled');

      $("#module-analysis--modules").trigger('change');
    });
  };

  const changeModule = () => {
    $("#module-analysis--modules").change((e) => {
      $("#module-analysis--modules").addClass('disabled');
      updateInformationCountries();
      $("#module-analysis--modules").removeClass('disabled');
      
      $("#module-analysis--countries").trigger('change');
    });
  };

  const changeCountry = () => {
    $("#module-analysis--countries").change((e) => {
      $("#module-analysis--countries").addClass('disabled');
      updateInformationSubmodules();
      $("#module-analysis--countries").removeClass('disabled');
    });
  };

  /*
   * ------------------------------------------ Main Processes ------------------------------------------
   */
  const initializer = () => {
    changeIntitutionLevel();
    changeModule();
    changeCountry();
  }


  /*
   * --------------------------------------------- Execute ---------------------------------------------
   */
  document.addEventListener("turbolinks:load", () => {
    if ($("#module-analysis--levels").length) {
      initializer();
      $("#module-analysis--levels").trigger('change');
    }
  });
})();