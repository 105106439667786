(() => {
  "use strict";
  /*
   * ------------------------------------------ Ajax Functions ------------------------------------------
   */
  const getCountriesStatistics = () => {
    return fetch(`/country_analysis/countries_statistics?${new URLSearchParams({ institution_level_id: document.getElementById('regional-analysis--levels').value })}`).then(response => response.json());
  };

  const getCountryStatistics = () => {
    return fetch(`/country_analysis/country_statistics?${new URLSearchParams({ institution_level_id: document.getElementById('regional-analysis--levels').value, country_id: document.getElementById('regional-analysis--countries').value})}`).then(response => response.json());
  };

  const updateCountriesStatistics = () => {
    return fetch(`/country_analysis/country_statistics?${new URLSearchParams({ institution_level_id: document.getElementById('regional-analysis--levels').value})}`).then(response => response.json());
  };

  /*
   * ------------------------------------------ Methods ------------------------------------------
   */
  const initializeChartStatsInsts = info => {
    let chart_id = 'regional-analysis--total-countries',
        data = { labels: [], datasets: [ { label: document.getElementById(chart_id).getAttribute('data-label'), data: [], backgroundColor: "#206CAD" } ] },
        data_percentage = { labels: [], datasets: [{ label: '%', backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] },
        color_rating = '';
    
    info.forEach(country => {
      data.labels.push(country.name_of);
      data.datasets[0].data.push(parseFloat(country.total_institutions));

      color_rating = window.commonVariables.color_rating(parseFloat(country.avg_institutions) || 0);
      data_percentage.labels.push(country.name_of);
      data_percentage.datasets[0].data.push(parseFloat(country.avg_institutions || 0));
      data_percentage.datasets[0].borderColor.push(color_rating);
      data_percentage.datasets[0].backgroundColor.push(`${color_rating}B3`);
    });

    window.chartRa = window.chartRa || {};
    try {
      window.chartRa[chart_id].destroy();
    } catch {};

    window.chartRa[chart_id] = new Chart(document.getElementById(chart_id).getContext('2d'), {
      type: 'bar',
      data: data,
      options: { 
        indexAxis: 'y',
        responsive: true, 
        plugins: {
          legend: {
            position: 'top',
          },
        }
      }
    });

    chart_id = 'regional-analysis--avg-countries';
    try {
      window.chartRa[chart_id].destroy();
    } catch {};

    window.chartRa[chart_id] = new Chart(document.getElementById(chart_id).getContext('2d'), {
      type: 'bar',
      data: data_percentage,
      options: { 
        responsive: true, 
        plugins: {
          annotation: {
            annotations: {
              red_zone: { type: 'box', yMin: 0, yMax: 39, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(182, 52, 52, 0.2)', borderColor: 'rgba(182, 52, 52)' },
              orange_zone: { type: 'box', yMin: 39, yMax: 60, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(255, 153, 0, 0.2)', borderColor: 'rgba(255, 153, 0)' },
              yellow_zone: { type: 'box', yMin: 60, yMax: 80, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(255, 243, 71, 0.2)', borderColor: 'rgba(255, 243, 71)' },
              green_zone: { type: 'box', yMin: 80, yMax: 100, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(159, 224, 27, 0.2)', borderColor: 'rgba(159, 224, 27)' }
            }
          }
        }
      }
    });

  };

  const initializeChartStatsTypeInsts = info => {
    let chart_id = 'regional-analysis--level-institutions-countries',
        global_chart_id = 'regional-analysis--level-institutions',
        data = { labels: Object.keys(info.info), datasets: [ ]},
        data_global = { labels: [document.getElementById(global_chart_id).getAttribute('data-label')], datasets: [ ] },
        bg_colors = ["#D94848B3", "#206CADB3"],
        countries = info.info,
        levels = info.levels,
        current_info;

    levels.forEach((lvl, idx) => {
      data.datasets.push({ label: lvl, data: [], backgroundColor: bg_colors[idx] } );
      data_global.datasets.push({ label: lvl, data: [ 0 ], backgroundColor: bg_colors[idx] } );
    });

    for (let country in countries) {
      levels.forEach(level => {
        current_info = (countries[country].filter(r => r[1] === level));
        data.datasets[data.datasets.findIndex(r => r.label == level)].data.push(current_info[0] ? current_info[0][2] : 0);
        data_global.datasets[data_global.datasets.findIndex(r => r.label == level)].data[0] += (current_info[0] ? current_info[0][2] : 0);
      });
    };

    window.chartRa = window.chartRa || {};
    try {
      window.chartRa[chart_id].destroy();
    } catch {};
    
    window.chartRa[chart_id] = new Chart(document.getElementById(chart_id).getContext('2d'), {
      type: 'bar',
      data: data,
      options: {
        indexAxis: 'y',
        responsive: true, 
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      }
    });

    try {
      window.chartRa[global_chart_id].destroy();
    } catch {};
    window.chartRa[global_chart_id] = new Chart(document.getElementById(global_chart_id).getContext('2d'), {
      type: 'bar',
      data: data_global,
      options: {
        responsive: true, 
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      }
    });
  };

  const initializeChartGlobalAvgModules = info => {
    let chart_id = 'regional-analysis--level-modules',
        data = { labels: [], datasets: [{ label: '%', backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] },
        color_rating = '', percentage = 0;

    info.forEach(module => {
      color_rating = window.commonVariables.color_rating(parseFloat(module.avg_module) || 0);
      data.labels.push(module.title);
      data.datasets[0].data.push(module.avg_module);
      data.datasets[0].borderColor.push(color_rating);
      data.datasets[0].backgroundColor.push(`${color_rating}B3`);
    });

    window.chartRa = window.chartRa || {};
    try {
      window.chartRa[chart_id].destroy();
    } catch {};

    window.chartRa[chart_id] = new Chart(document.getElementById(chart_id).getContext('2d'), {
      type: 'bar',
      data: data,
      options: { 
        responsive: true, 
        plugins: {
          annotation: {
            annotations: {
              red_zone: { type: 'box', yMin: 0, yMax: 39, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(182, 52, 52, 0.2)', borderColor: 'rgba(182, 52, 52)' },
              orange_zone: { type: 'box', yMin: 39, yMax: 60, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(255, 153, 0, 0.2)', borderColor: 'rgba(255, 153, 0)' },
              yellow_zone: { type: 'box', yMin: 60, yMax: 80, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(255, 243, 71, 0.2)', borderColor: 'rgba(255, 243, 71)' },
              green_zone: { type: 'box', yMin: 80, yMax: 100, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(159, 224, 27, 0.2)', borderColor: 'rgba(159, 224, 27)' }
            }
          }
        }
      }
    });
  };

  const initializeChartGlobalAvgCriterions = info => {
    let chart_id = 'regional-analysis--level-criteria',
        data = { labels: [], datasets: [{ label: '%', backgroundColor: [], borderColor: [], borderWidth: 2, data: [], }] },
        color_rating = '';

    info.forEach(criterion => {
      color_rating = window.commonVariables.color_rating(parseFloat(criterion[1]) || 0);
      data.labels.push(criterion[0]);
      data.datasets[0].data.push(criterion[1]);
      data.datasets[0].borderColor.push(color_rating);
      data.datasets[0].backgroundColor.push(`${color_rating}B3`);
    });

    window.chartRa = window.chartRa || {};
    try {
      window.chartRa[chart_id].destroy();
    } catch {};

    window.chartRa[chart_id] = new Chart(document.getElementById(chart_id).getContext('2d'), {
      type: 'bar',
      data: data,
      options: { 
        responsive: true, 
        plugins: {
          annotation: {
            annotations: {
              red_zone: { type: 'box', yMin: 0, yMax: 39, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(182, 52, 52, 0.2)', borderColor: 'rgba(182, 52, 52)' },
              orange_zone: { type: 'box', yMin: 39, yMax: 60, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(255, 153, 0, 0.2)', borderColor: 'rgba(255, 153, 0)' },
              yellow_zone: { type: 'box', yMin: 60, yMax: 80, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(255, 243, 71, 0.2)', borderColor: 'rgba(255, 243, 71)' },
              green_zone: { type: 'box', yMin: 80, yMax: 100, xMin: -1, xMax: data.labels.length, backgroundColor: 'rgba(159, 224, 27, 0.2)', borderColor: 'rgba(159, 224, 27)' }
            }
          }
        }
      }
    });
  };

  const updateChartAvgModules = info => {
    let chart_id = 'regional-analysis--level-modules',
        new_dataset = { label: document.getElementById('regional-analysis--countries').selectedOptions[0].innerText, data: [], borderColor: '#178FAF', backgroundColor: '#178FAFB3', type: 'line' },
        info_module;

    window.chartRa = window.chartRa || {};

    window.chartRa[chart_id].config.data.labels.forEach(module => {
      info_module = info.filter(r => r.title === module);
      new_dataset.data.push(info_module[0] ? info_module[0].avg_module : null);
    });

    window.chartRa[chart_id].data.datasets.splice(1, 1);
    window.chartRa[chart_id].data.datasets.push(new_dataset);
    window.chartRa[chart_id].update();
  };

  const updateChartAvgCriterions = info => {
    let chart_id = 'regional-analysis--level-criteria',
        new_dataset = { label: document.getElementById('regional-analysis--countries').selectedOptions[0].innerText, data: [], borderColor: '#178FAF', backgroundColor: '#178FAFB3', type: 'line' },
        info_module;

    window.chartRa = window.chartRa || {};

    window.chartRa[chart_id].config.data.labels.forEach(criterion => {
      info_module = info.filter(r => r[0] === criterion);
      new_dataset.data.push(info_module[0] ? info_module[0][1] : null);
    });

    window.chartRa[chart_id].data.datasets.splice(1, 1);
    window.chartRa[chart_id].data.datasets.push(new_dataset);
    window.chartRa[chart_id].update();
  };

  /*
   * ------------------------------------------ Main Functions ------------------------------------------
   */
  const setCountriesStatistics = async () => {
    let info = await getCountriesStatistics(),
        total_statistics = info.total_statistics;

    document.getElementById("total-countries-analyzed").innerHTML = total_statistics.total_countries;
    document.getElementById("total-institutions").innerHTML = total_statistics.total_institutions;
    document.getElementById("total-institutions-analyzed").innerHTML = total_statistics.total_institutions_analysis;

    initializeChartStatsInsts(info.statistics_institutions);
    initializeChartStatsTypeInsts(info.statistics_type_institutions);
    initializeChartGlobalAvgModules(info.global_avg_modules);
    initializeChartGlobalAvgCriterions(info.global_avg_criterions);
    setCountryStatistics();
  };

  const setCountryStatistics = async () => {
    let info = await getCountryStatistics();

    updateChartAvgModules(info.avg_modules);
    updateChartAvgCriterions(info.avg_criterions);
  };
  

  /*
   * ------------------------------------------ Main Processes ------------------------------------------
   */
  const initializer = () => {
    setCountriesStatistics();
  }

  /*
   * --------------------------------------------- Execute ---------------------------------------------
   */
  document.addEventListener("turbolinks:load", () => {
    if ($("#regional-analysis--total-countries").length) {
      initializer();

      $("#regional-analysis--levels").change(async event => {
        let info = await updateCountriesStatistics();
        $("#regional-analysis--countries").attr('disable', 'disabled');
        
        initializeChartGlobalAvgModules(info.avg_modules);
        initializeChartGlobalAvgCriterions(info.avg_criterions);
        setCountryStatistics();

        $("#regional-analysis--countries").attr('disable', '');
      });

      $("#regional-analysis--countries").change(event => {
        setCountryStatistics();
      });
    }
  });
})();