(() => {
  "use strict";

  const drawInstitutions = data => {
    let new_html = "";

    data.institutions.forEach(institution => new_html += `<option value="${institution.id}">${institution.long_name}</option>`);

    $("#geographical_area_institution_ids").html(new_html).val(data.selected).select2();
  };

  const getInstitutions = country => {
    $.ajax({
      url: "/institutions.json",
      method: "GET",
      data: { 
        institution: { 
          administrative_division_id: country
        },
        geographical_area: $("#geographical_area_institution_ids").data('area')
      },
      beforeSend: e => {
        $("#geographical_area_administrative_division_id").prop('disabled', true); 
        $("#geographical_area_institution_ids").prop('disabled', true);
      }
    }).done(data => {
      drawInstitutions(data);
      $("#geographical_area_administrative_division_id").prop('disabled', false);
      $("#geographical_area_institution_ids").prop('disabled', false);
    });
  };

  const updateInstitutionsChangeCountry = () => {
    getInstitutions($("#geographical_area_administrative_division_id").val());
    $("#geographical_area_administrative_division_id").change(e => { getInstitutions(e.currentTarget.value) });
  };

  document.addEventListener("turbolinks:load", () => {
    if ($("form#new_geographical_area").length > 0 || $("form[id^=edit_geographical_area]").length > 0) {
      updateInstitutionsChangeCountry();
    }
  });
})();