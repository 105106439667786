// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Base libraries/plugins
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import "popper.js";
import "@fortawesome/fontawesome-free/js/all";
import "bootstrap";
import "sweetalert";
import "select2";
import "bootstrap-datepicker";

// ------------------------------ Components required for Chartkick ----------------------------
require("chartkick");
import { Chart, ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle, annotationPlugin);

window.Chart = Chart;



// ----------------------------- Components required for DataTables ----------------------------
import JSZip from "jszip";
window.JSZip = JSZip;

var pdfMake = require( "pdfmake/build/pdfmake.js" );
var pdfFonts = require( "pdfmake/build/vfs_fonts.js" );
pdfMake.vfs = pdfFonts.pdfMake.vfs;

require( "datatables.net" );
require( "datatables.net-bs4" );
require( "datatables.net-buttons-bs4" )( window, $ );
require( "datatables.net-buttons/js/buttons.html5.js" )( window, $ );
require( "yadcf" );
import introJs from "intro.js";
window.introJs = introJs;

// Base Inspinia
import "./inspinia/slimscroll.min";
import "./inspinia/metisMenu"
import "./inspinia/inspinia";

// Import owner initializers
import "./own/ctrl_constant";
import "./own/init_sweetalert";
import "./own/ctrl_datatable";
import "./own/init_plugins";
import "./own/ctrl_assessment";
import "./own/ctrl_assessment_alert";
import "./own/ctrl_improvement_opportunity";
import "./own/ctrl_simulation_results";
import "./own/ctrl_ax_modules";
import "./own/ctrl_access_request";
import "./own/ctrl_analysis_institutions";
import "./own/ctrl_analysis_networks";
import "./own/ctrl_analysis_countries";
import "./own/ctrl_health_networks";
import "./own/ctrl_geographical_areas";
import "./own/ctrl_institution_statistics";
import "./own/ctrl_analysis_national";
import "./own/ctrl_mass_users";
import "./own/ctrl_analysis_modules";

// Import Styles
import "../stylesheets/application";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
