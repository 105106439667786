window.ctrlAssessmentAlert = (() => {
  /*
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */
  var I18N = {
    es: {
      with_evaluation: {
        title: 'Tiene una evaluación previa para este módulo finalizada, ¿Desea acceder a la evaluación terminada o desea realizar una nueva?',
        buttons: {
          continue: 'Continuar',
          new_assessment: 'Nueva evaluación'
        }
      }
    },
    en: {
      with_evaluation: {
        title: 'You have a previous evaluation for this finished module, do you want to access the finished evaluation or do you want to take a new one?',
        buttons: {
          continue: 'Continue',
          new_assessment: 'New assessment'
        }
      }
    }
  };

  /*
   * ------------------------------------------------------------------------
   * Getters
   * ------------------------------------------------------------------------
   */
  //
  getLang = () => {
    return $('html').attr('lang');
  };
  //
  getMissingEvaluate = (link) => {
    try {
      return parseInt($(link).data('missing-evaluate'));
    } catch (error) {
      return -1;
    }
  };
  //
  getTotalAnswers = (link) => {
    try {
      return parseInt($(link).data('total-answers'));
    } catch (error) {
      return -1;
    }
  };

  /*
   * ------------------------------------------------------------------------
   * Setters
   * ------------------------------------------------------------------------
   */

  /*
   * ------------------------------------------------------------------------
   * Ajax calls
   * ------------------------------------------------------------------------
   */
  //
  ajaxSendEvaluation = (current_link, information) => {
    $.ajax({
      url: $('#modules').data('url'),
      method: 'POST',
      data: information,
      beforeSend: () => { $(current_link).addClass('disabled') }
    }).done((data) => {
      location.href = data.url
    }).fail((xhr, text, error) => {
      console.log('Error');
    });
  };

  /*
   * ------------------------------------------------------------------------
   * method's
   * ------------------------------------------------------------------------
   */
  //
  alertNewAssessment = (current_link, information) => {
    /*swal(I18N[getLang()]['with_evaluation']['title'], {
      buttons: {
        continue: { value: 'continue', text: I18N[getLang()]['with_evaluation']['buttons']['continue'] },
        new_assessment: { value: 'new_assessment', text: I18N[getLang()]['with_evaluation']['buttons']['new_assessment'] }
      }
    }).then((value) => {
      switch (value) {
        case 'continue':
          ajaxSendEvaluation(current_link, information);
          break;
        case 'new_assessment':
          information.new_assessment = true;
          ajaxSendEvaluation(information);
          break;
      }
    });*/
    ajaxSendEvaluation(current_link, information);
  };
  
  /*
   * ------------------------------------------------------------------------
   * Initiators and observers
   * ------------------------------------------------------------------------
   */
  //
  loadSubModulesProgress = (element='[data-sweet-evaluation]') => {
    $(element).each((ind, el) => {
      var data = { ax_module: {
            institution_id: $(el).data('institution-id'),
            ax_module_id: $(el).data('ax-module'),
            total_levels: $(el).data('total-levels')
          } },
          current_card = $(el).parent().find('.card-text');
      $.ajax({
        url: '/parameterization/ax_modules/progress_submodules',
        method: 'GET',
        data: data
      }).done((data) => {
        var dom_table = "<div class='row'>";

        $(data).each((i, r) => {
          dom_table += `<div class="col-10 text-left">${r.code} - ${r.name_ancestor}</div><div class="col-2">${r.total_answers + r.total_does_not_apply} / ${r.total_questions}</div><div class="w-100"><hr/></div>`;
        });
        current_card.empty();
        current_card.html(dom_table);
      });
    });
  };
  //
  observeClickEvaluation = (element='[data-sweet-evaluation]') => {
    $(document).on('click', element, (e) => {
      var current_link = e.currentTarget,
          current_date = new Date(),
          information = {
            evaluation_heder: {
              administrative_division_id: $('#modules').data('country'),
              institution_id: $('#modules').data('institution'),
              ax_module_id: $(current_link).data('ax-module'),
              evaluation_date: current_date.getFullYear() + '/' + current_date.getMonth() + '/' + String(current_date.getDate()).padStart(2, '0')
            },
            new_assessment: false
          },
          missing_evaluate = getMissingEvaluate(current_link),
          total_answers = getTotalAnswers(current_link);
      
      if (missing_evaluate === 0 && total_answers > 0) {
        alertNewAssessment(current_link, information);
      } else {
        ajaxSendEvaluation(current_link, information)
      }
    });
  };

  document.addEventListener('turbolinks:load', () => {
    observeClickEvaluation();
    loadSubModulesProgress();
  });

  return {
    load_submodules: loadSubModulesProgress
  };
})();