(()=>{
  "use strict";

  const downloadExcelFormat = () => {
    try {
      document.getElementById('excel-format--mass-users').addEventListener('click', event => {
        event.preventDefault();
        let button = event.currentTarget,
            href_report = new URL(window.location.origin + button.getAttribute('href'));

        href_report.searchParams.set('country', document.getElementById('country_id').value);
        button.setAttribute('href', href_report.toString().replace(window.location.origin, ''))

        window.location = button.getAttribute('href');
      });
    } catch (error) {
      
    }
  };

  document.addEventListener('turbolinks:load', () => {
    downloadExcelFormat();
  });
})();