;(function($){
  $.fn.datepicker.dates['es'] = {
		days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    today: "Hoy",
    monthsTitle: "Meses",
    clear: "Borrar",
    weekStart: 1
  };
}(jQuery));

document.addEventListener("turbolinks:load", () => {
  "use strict";
  // Valid params - auto start guide
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('auto-start-guide')) {
    $("[data-start-guide]").toggleClass('animate-breathe');
  }
  // Init inputs with class select2
  $(".form-select2").select2();
  // Init inputs with class datapicker
  $(".datepicker-full").datepicker({
    setDate: new Date(),
    format: "yyyy/mm/dd",
    autoclose: true,
    clearBtn: true,
    language: $("html").attr("lang")
  });
  $(".datepicker-full").datepicker('setDate', new Date());
  // Guide (tour) from login views
  $("#start-guide-devise").click((e) => {
    window.introJs().start();
  });
  // General Guide (tour).
  $("#start-general-guide").click((e) => {
    var steps = [];

    $("#general-guide-steps li").each((k,v) => {
      steps.push({
        element: $(v).data('target'),
        intro: $(v).html()
      });
    });

    window.introJs().setOptions({ steps: steps }).start();
  });
  // Action for change language app
  $("#change-language").change((e) => {
    $.ajax({
      url: '/configuration/languages/change_language',
      method: 'POST',
      data: {
        language: $("#change-language").val()
      }
    }).done((data) => {
      Turbolinks.visit(window.location, { action: 'replace' });
    });
  });
  // Redirect to begin work
  $("#btn-begin-proccess").click((e) => {
    Turbolinks.visit($(e.currentTarget).data('url') + '?auto-start-guide=true');
  });
  // Guide from institutions
  $("[data-start-guide]").click((e) => {
    var steps = [];

    $(e.currentTarget).removeClass('animate-breathe');

    $("[data-guide-steps-section] li").each((k,v) => {
      steps.push({
        element: $(v).data('target'),
        intro: $(v).html()
      });
    });

    window.introJs().setOptions({ steps: steps }).start();
  });
  // Sesion front door
  $("[data-front-door]").click((e) => {
    let data = $(e.currentTarget).data('front-door');

    $("#user_action_selected").val(data);
    $("#front-door").addClass('d-none');
    $("#form-login").removeClass('d-none').addClass('animated fadeInRight');
  });
  $("#show-front-door").click((e) => {
    $("#form-login").addClass('d-none');
    $("#front-door").removeClass('d-none').addClass('animated bounceIn');
  });
});